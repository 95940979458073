<template>
  <div>
    <DetailsFlow overview="Companies" detail="Company" />
    <CompaniesOverview v-if="showDetails && !showCompanySubDetails" />
    <CompanyDeatils v-if="!showDetails && !showCompanySubDetails" />
    <SubscriptionDetails v-if="showCompanySubDetails" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DetailsFlow from "@/components/DetailsFlow.vue";
import CompaniesOverview from "@/components/CompaniesOverview.vue";
import CompanyDeatils from "@/components/CompanyDeatils.vue";
import SubscriptionDetails from "@/components/SubscriptionDetails.vue";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import { mapGetters } from "vuex";
import _ from "lodash";

export default defineComponent({
  name: "CompaniesView",
  components: {
    DetailsFlow,
    CompaniesOverview,
    CompanyDeatils,
    SubscriptionDetails,
  },
  computed: {
    ...mapGetters(["getCompanyData", "getSubscriptionData"]),
    showDetails(): any {
      if (_.isEmpty(this.getCompanyData)) {
        this.$store.dispatch(CompanyActionTypes.SET_LIC_USAGE, {});
        this.$store.dispatch(CompanyActionTypes.SET_COMPANY_USERCOUNT, {});
      }
      return _.isEmpty(this.getCompanyData);
    },
    showCompanySubDetails(): boolean {
      return !_.isEmpty(this.getSubscriptionData);
    },
  },
});
</script>
