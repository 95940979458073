<template>
  <!-- <div class="detcontent"> -->
  <div class="usrprdtext topmargin btm-2">
    <h4 class="font-bold-24">Assigned Products</h4>
    <div class="mb-4">
      <el-button
        plain
        class="delbtn"
        @click="showAddProducts"
        id="assignproduct"
      >
        <img src="@/assets/icons/add.svg" style="width: 40px" />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="assignAddon"
        id="addignaddon"
        :disabled="!enablebuttons"
      >
        <img
          src="@/assets/icons/addaddonenabled.svg"
          style="width: 40px"
          v-if="enablebuttons"
        />
        <img
          src="@/assets/icons/addaddondisabled.svg"
          style="width: 40px"
          v-if="!enablebuttons"
        />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="removeAddon"
        id="removeaddon"
        :disabled="!enablebuttons && DisableDeleteAddon"
      >
        <img
          src="@/assets/icons/deladdonenabled.svg"
          style="width: 40px"
          v-if="enablebuttons && !DisableDeleteAddon"
        />
        <img
          src="@/assets/icons/deladdondisabled.svg"
          style="width: 40px"
          v-if="DisableDeleteAddon"
        />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="removeEntitlement"
        id="removeentitlement"
        :disabled="!enablebuttons"
      >
        <img
          src="@/assets/icons/delete.svg"
          style="width: 40px"
          v-if="enablebuttons"
        />
        <img
          src="@/assets/icons/deletedisabled.svg"
          style="width: 40px"
          v-if="!enablebuttons"
        />
      </el-button>
    </div>
  </div>
  <AssignedProducts />
  <el-drawer
    v-model="showAddprod"
    direction="rtl"
    size="40%"
    destroy-on-close
    :before-close="closeModal"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :wrapperClosable="false"
  >
    <AssignProductsModal />
  </el-drawer>
  <el-drawer
    v-model="showAssignAddon"
    direction="rtl"
    size="40%"
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :wrapperClosable="false"
  >
    <AssignAddonModal />
  </el-drawer>
  <el-drawer
    v-model="showRemoveAddon"
    direction="rtl"
    size="40%"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeModal"
  >
    <RemoveAddonsModal />
  </el-drawer>
  <!-- </div> -->
</template>

<style lang="scss">
.usrprdtext {
  //   position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.optbtn {
  color: #000000;
  background: unset;
  border: unset;
  width: 50px;
  height: 50px;
  // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
  padding: unset;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import AssignedProducts from "@/components/AssignedProducts.vue";
import AssignProductsModal from "@/components/AssignProductsModal.vue";
import AssignAddonModal from "@/components/AssignAddonModal.vue";
import RemoveAddonsModal from "@/components/RemoveAddonsModal.vue";
import APIService from "@/utils/APIService";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "UserDetails",
  components: {
    AssignedProducts,
    AssignProductsModal,
    RemoveAddonsModal,
    AssignAddonModal,
  },
  computed: {
    ...mapGetters([
      "getShowAddProducts",
      "getSelectedProducts",
      "getUserData",
      "getShowAssignAddon",
      "getAddonProducts",
      "getShowRemoveAddon",
    ]),
    showAddprod: {
      get(): any {
        return this.getShowAddProducts;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, false);
      },
    },
    showAssignAddon: {
      get(): boolean {
        return this.getShowAssignAddon;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_ASSIGNADDON, false);
      },
    },
    showRemoveAddon: {
      get(): boolean {
        return this.getShowRemoveAddon;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_REMOVEADDON, false);
      },
    },
    DisableDeleteAddon(): boolean {
      return this.getAddonProducts.length === 0;
    },
    enablebuttons(): boolean {
      return this.getSelectedProducts.length !== 0;
    },
  },
  data() {
    return {};
  },
  methods: {
    showAddProducts() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "prodAss");
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, true);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, [
        this.getUserData,
      ]);
    },
    removeEntitlement() {
      const apiService = new APIService(this.axios);
      const entitlementslist = [] as any[];
      // eslint-disable-next-line array-callback-return
      this.getSelectedProducts.map((prod: any) => {
        const removeRequest = {
          productBucketId: prod.productBucketId,
          major: prod.majorVersionNumber,
          minor: prod.minorVersionNumber,
          patch: prod.patchVersionNumber,
        };

        entitlementslist.push(removeRequest);
      });
      const removeRequestData = {
        userBucketId: this.getUserData.userBucketId,
        entitlementsToRemove: entitlementslist,
      } as any;
      apiService.removeSubscriptions(removeRequestData).then((res) => {
        if (res.success) {
          apiService
            .getAssignedProdsList(this.getUserData.userBucketId, "Ascending")
            .then((resp) => {
              this.$store.dispatch(UserActionTypes.SET_ASSIGNED_PRODUCTS, resp);
            });
          ElMessage({
            message: "Entitlement has been removed successfully.",
            type: "success",
          });
        } else {
          ElMessage.error(res.validationResult.validationMessages[0]);
        }
      });
    },
    assignAddon() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "assignaddon");
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ASSIGNADDON, true);
    },
    removeAddon() {
      this.$store.dispatch(FlowActionTypes.SET_SHOW_REMOVEADDON, true);
    },
    closeModal() {
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, false);
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, []);
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "none");
      this.$store.dispatch(ProductActionTypes.SET_AVAILABLE_PRODUCTS, {
        items: [],
      });
      this.$store.dispatch(
        UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
        {}
      );
    },
  },
});
</script>
