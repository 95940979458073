<template>
  <el-row v-if="showsub">
    <div v-if="!showCards" class="subscontent">
      <el-table
        id="subscriptionslisttable"
        v-loading="loading"
        :data="subscriptionList"
        style="width: 100%"
        max-height="600"
        scrollbar-always-on
        :header-cell-style="headerstyle"
        :row-style="rowdatastyle"
        @row-click="rowClickHandler"
        empty-text="No subscriptions available"
      >
        <el-table-column
          label="Product Name"
          prop="productName"
          label-class-name="labelpadding"
          min-width="150"
        >
          <template #default="props">
            <div class="celldiv">
              <img src="@/assets/icons/vcicon.svg" class="cellimage" />
              <div class="celltext">
                <label style="margin: unset">{{
                  props.row.productName.substring(0, 18)
                }}</label>
                <label style="font-weight: 700">{{
                  props.row.productName.substring(18)
                }}</label>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Version" align="center">
          <template #default="props">
            {{ props.row.majorVersionNumber }}.{{
              props.row.minorVersionNumber
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="License Type" prop="licenseType">
        </el-table-column> -->
        <el-table-column label="Product Seats" align="center">
          <template #default="props">
            {{ props.row.seatsUsed }}/{{ props.row.seats }}
          </template>
        </el-table-column>
        <el-table-column label="Add-on" align="center" prop="addOns">
          <template #default="props">
            <div v-for="(addon, index) in props.row.addOns" :key="index">
              {{ addon.productName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Add-on Seats" align="center">
          <template #default="props">
            <div v-for="(addon, index) in props.row.addOns" :key="index">
              {{ addon.seatsUsed }}/{{ addon.seats }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="" width="150">
          <template #default="subcription">
            <el-button
              @click="viewDetails(subcription.row)"
              plain
              link
              size="small"
            >
              <img
                src="@/assets/icons/openlistdetails.svg"
                style="width: 25px"
              />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="usermodalpagingdiv" v-if="totalPages > 1">
        <label class="pagelabel"
          ><strong>Page {{ currentPage }} / {{ totalPages }}</strong></label
        >
        <el-button
          plain
          class="paginationbtn"
          @click="currentPage = 1"
          id="firstpage"
          :disabled="currentPage === 1"
        >
          <img
            src="@/assets/icons/pagefirstenabled.svg"
            style="width: 20px"
            v-if="currentPage !== 1"
          />
          <img
            src="@/assets/icons/pagefirstdisabled.svg"
            style="width: 20px"
            v-if="currentPage === 1"
          />
        </el-button>
        <el-pagination
          layout="prev, next"
          :total="totalRowsCount"
          :page-size="10"
          v-model:current-page="currentPage"
        />
        <el-button
          plain
          class="paginationbtn"
          @click="currentPage = totalPages"
          id="lastpage"
          :disabled="currentPage === totalPages"
        >
          <img
            src="@/assets/icons/pagelastenabled.svg"
            style="width: 20px"
            v-if="currentPage !== totalPages"
          />
          <img
            src="@/assets/icons/pagelastdisabled.svg"
            style="width: 20px"
            v-if="currentPage === totalPages"
          />
        </el-button>
      </div>
    </div>
    <div v-if="showCards" class="accdiv">
      <div class="prdtext">
        <label class="accprd">Product Subscriptions</label>
      </div>
      <div class="prdtextdetail">
        <label class="labeltext"
          >All manageable product subscriptions from your administered
          companies</label
        >
      </div>
      <div class="carddeck">
        <div
          class="subcarddiv bottommargin"
          v-for="(prod, index) in subscriptionList"
          :key="index"
        >
          <div class="subaccprddiv">
            <img
              src="@/assets/icons/vcicon.svg"
              style="width: 55px; margin-right: 20px"
            />
            <div
              style="text-align: start; display: flex; flex-direction: column"
            >
              <label class="accprd">{{ prod.productName }}</label>
              <label
                >Version: {{ prod.majorVersionNumber }}&#46;{{
                  prod.minorVersionNumber
                }}</label
              >
            </div>
          </div>
          <div class="subdatadiv">
            <div class="subseat">
              <label class="labeltext">Seats used: </label>
              <h5 class="accprd">{{ prod.seatsUsed }}&#47;{{ prod.seats }}</h5>
            </div>
            <el-button
              round
              class="slicbtn"
              id="viewDetails"
              type="warning"
              @click="viewDetails(prod)"
            >
              Manage
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </el-row>
</template>

<style lang="scss">
.subscontent {
  box-sizing: border-box;
  flex-direction: column;
  margin: 0;
  padding: 1% 2vw 1% 0;
  width: 100%;
}
.prdtext {
  padding-top: 2rem;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.prdtextdetail {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.accdiv {
  padding: 1% 12% 2% 0%;
  width: 100%;
}
.card-deck .card {
  margin-bottom: 1rem;
}
.carddeck {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  column-gap: 2rem;
  row-gap: 2rem;
  align-items: center;
  justify-items: center;
}

.subcarddiv {
  position: relative;
  min-width: 30rem;
  width: 100%;
  display: flex;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.25rem;
  text-align: left;
  flex-direction: column;
  // padding: 0 50px;
  height: 12rem;
}
.accprd {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #101010;
}
.labeltext {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #101010;
  white-space: nowrap;
}
// .el-table th.el-table__cell>.cell:first-of-type{
//   margin-left: 30px;
// }

.subseat {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22%;
  column-gap: 5px;
}

.subdatadiv {
  display: flex;
  justify-content: space-between;
  padding: 1rem 4.5rem 2rem 4.5rem;
}
.slicbtn {
  font-family: "Open Sans", sans-serif;
  color: #ffffff !important;
  background: #ffbd2c;
  border: 2px solid #ffbd2c;
  font-size: 16px;
  line-height: 24px;
  width: 140px;
  margin-left: 1.5rem;
  border-radius: 50rem !important;
}
.slicbtn:active,
.slicbtn:focus,
.slicbtn:hover {
  border: 2px solid #ffbd2c;
  background: #ffbd2c;
  color: #ffffff;
}
.subaccprddiv {
  align-items: center;
  display: inline-flex;
  padding: 2rem 0 1rem 50px;
  width: 100%;
}
.el-table__expanded-cell {
  background-color: #efefef;
}
// .el-table .el-table__cell {
//   padding: 8px 0 8px 50px;
// }
.labelpadding {
  padding-left: 38px !important;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import APIService from "@/utils/APIService";
import _ from "lodash";
import { mapGetters } from "vuex";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";
// import { UserActionTypes } from "@/store/userstore/UserActionTypes";

export default defineComponent({
  name: "SubscriptionList",
  components: {},
  emits: ["subsListLoaded"],
  computed: {
    ...mapGetters(["getSubscriptionsList"]),
    subscriptionList(): any {
      if (!_.isEmpty(this.getSubscriptionsList)) {
        this.updatePaginationData(this.getSubscriptionsList.pagingData);
      }
      return this.getSubscriptionsList.items;
    },
    showCards(): any {
      return this.$route.name === "account";
    },
    showsub(): any {
      return _.size(this.getSubscriptionsList.items) !== 0;
    },
    loadingFinished(): boolean {
      return !this.loading;
    },
  },
  data() {
    return {
      newprodList: [],
      loading: true,
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      currentPage: 1,
      perPage: 10,
      pagesNumber: 1,
      totalPages: 0,
      totalRowsCount: 0,
    };
  },
  watch: {
    currentPage: function watchForChanges(currPage, prevPage) {
      if (currPage !== prevPage) {
        this.loading = true;
        const apiService = new APIService(this.axios);
        const productObject = {
          pageOptions: {
            skip: this.currentPage - 1,
            take: 10,
            sortField: 11,
            sortOrder: 0,
          },
        };
        apiService
          .getSubscriptionsList(productObject)
          .then((res) => {
            this.loading = false;
            this.$store.dispatch(ProductActionTypes.SET_SUBSCRIPTIONS, res);
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
            // else this.$store.dispatch(UserActionTypes.SET_USER_ERROR, true);
          });
      }
    },
  },
  methods: {
    async getSubscriptions() {
      if (useIsAuthenticated().value) {
        const productObject = {
          pageOptions: {
            skip: 0,
            take: 10,
            sortField: 11,
            sortOrder: 0,
          },
        };
        const apiService = new APIService(this.axios);
        apiService
          .getSubscriptionsList(productObject)
          .then((res) => {
            this.loading = false;
            this.$store.dispatch(ProductActionTypes.SET_SUBSCRIPTIONS, res);
            this.emitLoadingStatus();
            this.updatePaginationData(res.pagingData);
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
            // else this.$store.dispatch(UserActionTypes.SET_USER_ERROR, true);
          });
      }
    },
    updatePaginationData(pagingData: any) {
      this.currentPage =
        this.currentPage !== 0 ? this.currentPage : pagingData.index;
      this.totalPages = pagingData.totalPages;
      this.totalRowsCount = pagingData.itemCount;
    },
    async viewDetails(sub: any) {
      if (this.$route.name === "account") this.$router.push("/products");
      this.$store.dispatch(FlowActionTypes.SET_ADDON_DATA, sub.addOns);
      await this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, sub);
    },
    rowClickHandler(record: any) {
      // const addon = {
      //   productName: "KUKA OLP",
      //   majorVersionNumber: 4,
      //   minorVersionNumber: 2,
      //   patchVersionNumber: null,
      //   seats: 1,
      //   seatsUsed: 0,
      // };

      this.$store.dispatch(FlowActionTypes.SET_ADDON_DATA, record.addOns);
      this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, record);
    },
    emitLoadingStatus() {
      this.$emit("subsListLoaded", this.loadingFinished);
    },
  },
  mounted() {
    this.getSubscriptions();
  },
  updated() {
    this.emitLoadingStatus();
  },
});
</script>
