import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailsFlow = _resolveComponent("DetailsFlow")!
  const _component_SubscriptionDetails = _resolveComponent("SubscriptionDetails")!
  const _component_ProductsList = _resolveComponent("ProductsList")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_SubscriptionsList = _resolveComponent("SubscriptionsList")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DetailsFlow, {
      overview: _ctx.activeTab,
      detail: _ctx.detailName
    }, null, 8 /* PROPS */, ["overview", "detail"]),
    (!_ctx.showSubscription)
      ? (_openBlock(), _createBlock(_component_SubscriptionDetails, { key: 0 }))
      : _createCommentVNode("v-if", true),
    (_ctx.showProducts && _ctx.showSubscription)
      ? (_openBlock(), _createBlock(_component_el_tabs, {
          key: 1,
          modelValue: _ctx.activeTab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
          class: "tabstyle",
          type: "card"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tab_pane, {
              label: "Licensed Products",
              name: "Products",
              disabled: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ProductsList)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_tab_pane, {
              label: "Subscriptions",
              name: "Subscriptions"
            }, {
              default: _withCtx(() => [
                (_ctx.showSubscription)
                  ? (_openBlock(), _createBlock(_component_SubscriptionsList, { key: 0 }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}