<template>
  <h4 class="modal-title" id="removeEntitlementtitle">REMOVE Entitlements</h4>
  <div class="modalbody" v-if="!removalDone">
    <div class="datadiv bottommargin" v-if="showUserConfirm">
      <label class="headerfont">
        Selected entitlements and addons will be removed from the user
      </label>
      <div class="prodoverflowdiv topmargin">
        <div v-for="(item, index) in selectedProducts" :key="index">
          <label class="summarytitle btm-1">Product Name</label>
          <div class="titlestyle btm-1">{{ item.productName }}</div>
          <div class="btm-1">
            Version: {{ item.majorVersionNumber }}.{{ item.minorVersionNumber }}
          </div>
          <label class="summarytitle btm-1" v-if="item.addOns.length !== 0">
            With Add-Ons:</label
          >
          <div v-for="(addon, index) in item.addOns" :key="index">
            <div class="titlestyle btm-1">{{ addon.productName }}</div>
            <div class="bottommargin">
              Version: {{ addon.majorVersionNumber }}.{{
                addon.minorVersionNumber
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="datadiv bottommargin" v-if="!showUserConfirm">
      <label class="headerfont">
        Entitlements and addons will be removed from selected users
      </label>
      <div class="prodoverflowdiv topmargin">
        <div v-for="(item, index) in selectedProductUsers" :key="index">
          <label class="summarytitle btm-1">User Name</label>
          <div class="titlestyle btm-1">
            {{ item.name }} {{ item.userName }}
          </div>
          <label class="summarytitle btm-1">Email</label>
          <div class="titlestyle btm-1">{{ item.email }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="buttonsdiv" v-if="!removalDone">
    <el-button
      id="assignaddonnext"
      round
      class="btnstyle ml-4"
      variant="warning"
      v-if="showUserConfirm"
      @click="removeUserEntitlements"
      :disabled="!enableNext"
    >
      <i v-if="!enableNext && !showError" class="fa fa-refresh fa-spin"></i>
      Confirm
    </el-button>
    <el-button
      id="assignaddonnext"
      round
      class="btnstyle ml-4"
      variant="warning"
      v-if="!showUserConfirm"
      @click="removeProductEntitlements"
      :disabled="!enableNext"
    >
      <i v-if="!enableNext && !showError" class="fa fa-refresh fa-spin"></i>
      Confirm
    </el-button>
    <el-button
      id="prodassigncancel"
      round
      variant="outline-dark"
      @click="cancelRemoval"
      class="cancelbtn"
      >Cancel</el-button
    >
  </div>

  <div v-if="removalDone" class="modalbody">
    <div class="font-bold-16" v-if="removeSuccess">
      Selected entitlements have been removed successfully.
    </div>
    <div v-if="showError">
      <div class="btm-2">Below errors occured for the selected users:</div>

      <div
        v-for="(item, index) in errorsList"
        :key="index"
        class="font-bold-16 btm-1"
      >
        <label class="summarytitle btm-1">User Name: </label>
        <label class="font-bold-16 btm-1">{{ item.errorUser }}</label>
        <div
          v-for="(prod, index) in item.errorProducts"
          :key="index"
          class="font-bold-16 btm-1"
        >
          <label class="summarytitle btm-1">Product Name: </label>
          <label class="font-bold-16 btm-1">{{ prod.productName }}</label
          ><br />
          <label class="summarytitle btm-1">Version: </label>
          <label class="btm-2">
            {{ prod.majorVersionNumber }}.{{ prod.minorVersionNumber }}
          </label>
        </div>
        <div v-if="showError" style="display: flex" class="maxitems">
          <img
            src="@/assets/icons/warnerror.svg"
            class="cellimage"
            style="margin-top: 10px"
          />
          <label class="errordiv" style="padding: 1rem 0">
            Error occured during removal of entitlements.</label
          >
        </div>
        <!-- <div class="font-bold-16 btm-1">{{ item.errorMessages }}</div> -->
        <!-- <p>
          r {{ item.errorUser }}<br />
          &#40;{{ item.errorMessages }} &#41;
        </p> -->
      </div>
    </div>
    <div class="buttonsdiv">
      <el-button
        round
        class="btnstyle ml-4"
        variant="warning"
        id="prodassingconfirm"
        @click="cancelRemoval"
      >
        Done
      </el-button>
    </div>
  </div>
</template>

<style lang="scss">
.prodoverflowdiv {
  max-height: 80% !important;
  overflow: auto;
  border: 1px solid #bdbdbd;
  padding: 8px 12px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
// import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";
// import { ElMessage } from "element-plus";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import _ from "lodash";
// import ErrorMessage from "./ErrorMessage.vue";

export default defineComponent({
  name: "RemoveAddonsModal",
  components: {},
  computed: {
    ...mapGetters([
      "getSelectedProducts",
      "getUserData",
      "getSearchValue",
      "getSubscriptionData",
      "getSelectedUserids",
      "getSelectedUsers",
      "getProductData",
      "getCurrentPage",
      "getCompanyData",
    ]),
    selectedProducts(): any {
      return this.getSelectedProducts !== undefined &&
        this.getSelectedProducts.length !== 0
        ? this.getSelectedProducts
        : [];
    },
    selectedProductUsers(): any {
      return this.getSelectedUsers !== undefined &&
        this.getSelectedUsers.length !== 0
        ? this.getSelectedUsers
        : [];
    },
    showUserConfirm() {
      return this.$route.name === "user" ? true : false;
    },
    currentPage(): number {
      return this.getCurrentPage;
    },
  },
  data() {
    return {
      headerstyle: {
        "border-bottom": "unset",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      selectedAddonsList: [] as any[],
      enableNext: true,
      removalDone: false,
      removeSuccess: false,
      showError: false,
      errorsList: [] as any[],
    };
  },
  methods: {
    getProductId() {
      let id;
      if (!_.isEmpty(this.getProductData)) {
        id = this.getProductData;
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        id = this.getSubscriptionData;
      }
      return id;
    },
    removeUserEntitlements() {
      const apiService = new APIService(this.axios);
      const entitlementslist = [] as any[];
      // eslint-disable-next-line array-callback-return
      this.getSelectedProducts.map((prod: any) => {
        const removeRequest = {
          productBucketId: prod.productBucketId,
          major: prod.majorVersionNumber,
          minor: prod.minorVersionNumber,
          patch: prod.patchVersionNumber,
        };

        entitlementslist.push(removeRequest);
      });
      const removeRequestData = {
        userBucketId: this.getUserData.userBucketId,
        entitlementsToRemove: entitlementslist,
      } as any;
      this.enableNext = true;
      apiService
        .removeSubscriptions(removeRequestData)
        .then((res) => {
          if (res.areAllSuccessfull) {
            const userDataObj = {
              userBucketIdList: [this.getUserData.userBucketId],
              pageOptions: {
                sortOrder: 0,
              },
            };
            apiService
              .getAssignedProdsList(userDataObj)
              .then((resp) => {
                this.$store.dispatch(
                  UserActionTypes.SET_ASSIGNED_PRODUCTS,
                  resp
                );
              })
              .catch((error) => {
                if (error.code === "ECONNABORTED")
                  this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
              });

            this.removalDone = true;
            this.removeSuccess = true;
          } else {
            this.removalDone = true;
            this.showError = true;
            var productError = [] as any[];
            res.responses.map((item: any) => {
              item.removalResponses.map((productInfo: any) => {
                if (!productInfo.success && productInfo.productName === null) {
                  productError = this.getSelectedProducts;
                } else if (
                  !productInfo.success &&
                  productInfo.productName !== null
                )
                  productError.push(productInfo);
              });
              this.errorsList.push({
                errorMessages: item.removalResult.errorMessages.join(". "),
                errorUser: item.userName,
                errorProducts: productError,
              });
            });
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED")
            this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
        });
    },
    removeProductEntitlements() {
      const apiService = new APIService(this.axios);
      const prodData = this.getProductId();
      const multipleRequestData = [] as any[];
      this.getSelectedUserids.map((userid: string) => {
        const removeRequest = {
          userBucketId: userid,
          entitlementsToRemove: [
            {
              productBucketId: prodData.productBucketId,
              major: prodData.majorVersionNumber,
              minor: prodData.minorVersionNumber,
              patch: prodData.patchVersionNumber,
            },
          ],
        } as any;
        multipleRequestData.push(removeRequest);
      });
      this.enableNext = false;
      if (!_.isEmpty(this.getProductData)) {
        apiService
          .removeEntitlements(multipleRequestData)
          .then((res) => {
            if (res.areAllSuccessfull) {
              apiService
                .getAssignedUsersList(
                  prodData.productBucketId,
                  null,
                  this.currentPage - 1,
                  10
                )
                .then((resp) => {
                  const prodInfo = {
                    productBucketId: prodData.productBucketId,
                    majorVersionNumber: prodData.majorVersionNumber,
                    minorVersionNumber: prodData.minorVersionNumber,
                    patchVersionNumber: prodData.patchVersionNumber,
                  };
                  apiService
                    .getProduct(prodInfo)
                    .then((prod) => {
                      this.$store.dispatch(
                        FlowActionTypes.SET_PRODUCT_DATA,
                        prod.items[0]
                      );
                    })
                    .catch((error) => {
                      if (error.code === "ECONNABORTED")
                        this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
                    });
                  this.$store.dispatch(
                    ProductActionTypes.SET_ASSIGNED_USERS,
                    resp
                  );
                })
                .catch((error) => {
                  if (error.code === "ECONNABORTED")
                    this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
                });
              this.removalDone = true;
              this.removeSuccess = true;
              this.enableNext = true;
            } else {
              this.removalDone = true;
              this.showError = true;
              var productError = [] as any[];
              res.responses.map((item: any) => {
                item.removalResponses.map((productInfo: any) => {
                  if (
                    !productInfo.success &&
                    productInfo.productName === null
                  ) {
                    productError = [
                      {
                        productName: prodData.productName,
                        majorVersionNumber: prodData.majorVersionNumber,
                        minorVersionNumber: prodData.minorVersionNumber,
                        patchVersionNumber: prodData.patchVersionNumber,
                      },
                    ];
                  } else if (
                    !productInfo.success &&
                    productInfo.productName !== null
                  )
                    productError.push(productInfo);
                });
                this.errorsList.push({
                  errorMessages: item.removalResult.errorMessages.join(". "),
                  errorUser: item.userName,
                  errorProducts: productError,
                });
              });
            }
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
          });
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        apiService
          .removeMultipleSubscriptions(multipleRequestData)
          .then((res) => {
            if (res.areAllSuccessfull) {
              const subData = {
                Searchphrase: this.getSearchValue,
                productBucketId: this.getSubscriptionData.productBucketId,
                companyBucketId:
                  this.$route.name === "companies"
                    ? this.getCompanyData.companyBucketId
                    : null,
                Major: this.getSubscriptionData.majorVersionNumber,
                Minor: this.getSubscriptionData.minorVersionNumber,
                Patch: this.getSubscriptionData.patchVersionNumber,
                pageOptions: {
                  skip: 0,
                  take: 10,
                  sortField: null,
                  sortOrder: null,
                },
              };
              apiService
                .getAssignedUsersSubList(subData)
                .then((resp) => {
                  const subInfo = {
                    productBucketId: this.getSubscriptionData.productBucketId,
                    majorVersionNumber:
                      this.getSubscriptionData.majorVersionNumber,
                    minorVersionNumber:
                      this.getSubscriptionData.minorVersionNumber,
                    patchVersionNumber:
                      this.getSubscriptionData.patchVersionNumber,
                  };
                  apiService
                    .getProduct(subInfo)
                    .then((prod) => {
                      this.$store.dispatch(
                        FlowActionTypes.SET_SUBSCRIPTION_DATA,
                        prod.items[0]
                      );
                    })
                    .catch((error) => {
                      if (error.code === "ECONNABORTED")
                        this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
                    });
                  this.$store.dispatch(
                    ProductActionTypes.SET_ASSIGNED_USERS,
                    resp
                  );
                })
                .catch((error) => {
                  if (error.code === "ECONNABORTED")
                    this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
                });
              this.removalDone = true;
              this.removeSuccess = true;
              this.enableNext = true;
            } else {
              this.removalDone = true;
              this.showError = true;
              var productError = [] as any[];
              res.responses.map((item: any) => {
                item.removalResponses.map((productInfo: any) => {
                  if (
                    !productInfo.success &&
                    productInfo.productName === null
                  ) {
                    productError = [
                      {
                        productName: prodData.productName,
                        majorVersionNumber: prodData.majorVersionNumber,
                        minorVersionNumber: prodData.minorVersionNumber,
                        patchVersionNumber: prodData.patchVersionNumber,
                      },
                    ];
                  } else if (
                    !productInfo.success &&
                    productInfo.productName !== null
                  )
                    productError.push(productInfo);
                });
                this.errorsList.push({
                  errorMessages: item.removalResult.errorMessages.join(". "),
                  errorUser: item.userName,
                  errorProducts: productError,
                });
              });
            }
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
          });
      }
    },
    cancelRemoval() {
      this.$store.dispatch(FlowActionTypes.SET_SHOW_REMOVEENTITLEMENT, false);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_PRODUCTS, []);
    },
  },
});
</script>
