<template>
  <DetailsFlow :overview="activeTab" :detail="detailName" />
  <SubscriptionDetails v-if="!showSubscription" />
  <el-tabs
    v-model="activeTab"
    class="tabstyle"
    type="card"
    v-if="showProducts && showSubscription"
  >
    <el-tab-pane label="Licensed Products" name="products" disabled>
      <ProductsList />
    </el-tab-pane>
    <el-tab-pane label="Subscriptions" name="Subscriptions">
      <SubscriptionsList v-if="showSubscription" />
    </el-tab-pane>
  </el-tabs>
</template>
<style lang="scss">
.tabstyle {
  flex-direction: column;
  text-align: start;
}
.el-tabs__item.is-disabled {
  border-bottom: 1px solid #e1e1e1 !important;
  background: #d6d8da;
}
.el-tabs--card > .el-tabs__header {
  border-bottom: unset;
  height: 50px !important;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import DetailsFlow from "@/components/DetailsFlow.vue";
import ProductsList from "@/components/ProductsList.vue";
import SubscriptionsList from "@/components/SubscriptionsList.vue";
import SubscriptionDetails from "@/components/SubscriptionDetails.vue";
import { mapGetters } from "vuex";
import _ from "lodash";

export default defineComponent({
  name: "ProductsView",
  components: {
    DetailsFlow,
    ProductsList,
    SubscriptionsList,
    SubscriptionDetails,
  },
  data() {
    return {
      activeTab: "Subscriptions",
    };
  },
  computed: {
    ...mapGetters(["getProductData", "getSubscriptionData", "getCurrentUser"]),
    showProducts(): any {
      return _.isEmpty(this.getProductData);
    },
    showSubscription(): any {
      return _.isEmpty(this.getSubscriptionData);
    },
    currentUser(): any {
      return _.isEmpty(this.getCurrentUser);
    },
    detailName(): string {
      return !_.isEmpty(this.getProductData) ? "Product" : "Subscription";
    },
  },
});
</script>
