<template>
  <el-row :class="showHeader ? 'accrow' : 'companyrow'">
    <h1 class="font-bold" v-if="showHeader">Overview</h1>
    <h4 class="font-regular" v-if="showHeader">{{ currentUserCompany }}</h4>
    <h4 class="font-bold header-font" v-if="!showHeader">
      Product license &#38; subscription
    </h4>
  </el-row>
  <el-row>
    <el-col :span="8" :offset="0">
      <el-card class="box-card" v-if="subInfo !== ''">
        <template #header>
          <div class="titlediv titlestyle">
            <label>
              <img
                src="@/assets/icons/subscriptionicon.svg"
                style="width: 25px"
              />
              Subscriptions
            </label>
            <label>{{ subInfo }}</label>
          </div>
        </template>
        <div style="margin-bottom: 1.5rem" class="cardtext_font">
          <div class="titlediv marginbottom">
            <label>Free seats</label>
            <label class="titlestyle">{{ subfreeSeats }}</label>
          </div>
          <!-- <div class="titlediv">
            <label>Expiration in next 30 days</label>
            <label class="titlestyle">{{ subexpiration }}</label>
          </div> -->
        </div>
        <div class="cardbuttondiv">
          <el-button
            plain
            variant="light"
            class="doptbtn"
            @click="showAddProducts"
            id="assignProducts"
          >
            <img src="@/assets/icons/add.svg" style="width: 40px" />
          </el-button>
          <label class="cardbuttontext"> Assign product</label>
        </div>
      </el-card>
    </el-col>
    <el-col :span="8" :offset="1">
      <el-card class="box-card" v-if="userInfo !== ''">
        <template #header>
          <div class="titlediv titlestyle">
            <label>
              <img src="@/assets/icons/usericon.svg" style="width: 25px" />
              Users
            </label>
            <label>{{ usersTotal }}</label>
          </div>
        </template>
        <div style="margin-bottom: 1.5rem">
          <div class="titlediv marginbottom">
            <label>
              <img src="@/assets/icons/active.svg" style="width: 20px" />
              &nbsp; &nbsp; Active users
            </label>
            <label class="titlestyle">{{ usersActive }}</label>
          </div>
          <div class="titlediv">
            <label>
              <img src="@/assets/icons/inactive.svg" style="width: 20px" />
              &nbsp; &nbsp; Inactive users
            </label>
            <label class="titlestyle">{{ usersInactive }}</label>
          </div>
        </div>
        <div class="cardbuttondiv">
          <el-button
            plain
            variant="light"
            class="doptbtn"
            @click="assignUsers"
            id="adduser"
          >
            <img src="@/assets/icons/add.svg" style="width: 40px" />
          </el-button>
          <label class="cardbuttontext"> Invite new user</label>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-drawer
    v-model="showAddprod"
    direction="rtl"
    size="40%"
    destroy-on-close
    :before-close="closeModal"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <AssignProductsModal />
  </el-drawer>
  <el-drawer
    v-model="showAdduser"
    direction="rtl"
    size="40%"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeModal"
  >
    <AddUserModal :showheading="true" />
  </el-drawer>
  <!-- </el-row> -->
</template>

<style lang="scss">
@media screen and (max-width: 768px) {
  div.optdivstyl {
    margin-top: 1rem;
  }
}

.titlediv {
  display: flex;
  justify-content: space-between;
}

.b-sidebar-outer {
  z-index: calc(2000 + 5);
}

.font-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 43.58px;
  margin: unset;
}

.header-font {
  font-size: 24px;
  line-height: 27.24px;
}

.el-divider--horizontal {
  margin-top: unset;
}

.marginbottom {
  margin-bottom: 1em;
}

.cardtext_font {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 20.55px;
  min-height: 64px;
}

.font-regular {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27.24px;
}
.accrow {
  flex-direction: column;
  text-align: start;
}
.companyrow {
  flex-direction: column;
  text-align: start;
  padding: 4% 14% 3% 1%;
}

.cardbuttontext {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24.51px;
  margin-left: 10px;
}

.cardbuttondiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
}

.doptbtn {
  color: #101010;
  background: unset;
  border: unset;
  // width: 2rem;
  height: 50px;
  padding: unset;
}

.optdivstyl {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import AssignProductsModal from "@/components/AssignProductsModal.vue";
import AddUserModal from "@/components/AddUserModal.vue";
import APIService from "@/utils/APIService";
import _ from "lodash";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";

export default defineComponent({
  name: "DashboardCards",
  components: {
    AssignProductsModal,
    AddUserModal,
  },
  emits: ["dashboardCardsLoaded"],
  computed: {
    ...mapGetters([
      "getShowAdduser",
      "getCurrentUser",
      "getShowAddProducts",
      "getLicUsage",
      "getCompUserCount",
      "getCompanyData",
    ]),
    currentUserCompany(): any {
      return _.isEmpty(this.getCurrentUser)
        ? ""
        : this.getCurrentUser.result.companyName;
    },
    showAddprod: {
      get(): any {
        return this.getShowAddProducts;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, false);
      },
    },
    showAdduser: {
      get(): any {
        return this.getShowAdduser;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      },
    },
    subInfo(): any {
      if (
        _.isEmpty(this.getLicUsage) &&
        !_.isEmpty(this.getCurrentUser) &&
        !this.subLicUsageLoaded
      ) {
        const companyId =
          this.$route.name === "companies"
            ? this.getCompanyData.companyBucketId
            : this.getCurrentUser.result.companyBucketId;
        this.getSubLicUsage(companyId);
      }
      if (!_.isEmpty(this.getLicUsage)) this.setSubLicUsageLoaded(true);
      return _.isEmpty(this.getLicUsage)
        ? this.subLicUsageLoaded
          ? "N/A"
          : ""
        : this.getLicUsage[0].subscriptionCount;
    },
    prodInfo(): any {
      return _.isEmpty(this.getLicUsage)
        ? this.subLicUsageLoaded
          ? "N/A"
          : ""
        : `${this.getLicUsage[0].subscriptionUsed}/${this.getLicUsage[0].subscriptionCount}`;
    },
    userInfo(): any {
      let companyUserCount = "";
      const companyId =
        this.$route.name === "companies"
          ? this.getCompanyData.companyBucketId
          : !_.isEmpty(this.getCurrentUser)
          ? this.getCurrentUser.result.companyBucketId
          : "";
      if (_.isEmpty(this.getCompUserCount) && !this.companyUserCountLoaded) {
        this.getCompanyUserCount(companyId);
      }
      if (!_.isEmpty(this.getCompUserCount)) {
        companyUserCount = _.find(this.getCompUserCount, {
          companyBucketId: companyId,
        });
        this.setCompanyUserCountLoaded(true);
      } else if (this.companyUserCountLoaded) {
        // eslint-disable-next-line prefer-destructuring
        companyUserCount = this.getCompUserCount[0];
      }
      return companyUserCount || this.companyUserCountLoaded || "";
    },
    subfreeSeats(): any {
      return _.isEmpty(this.getLicUsage)
        ? this.subLicUsageLoaded
          ? "N/A"
          : ""
        : this.getLicUsage[0].subscriptionCount -
            this.getLicUsage[0].subscriptionUsed;
    },
    subexpiration(): any {
      return _.isEmpty(this.getLicUsage)
        ? this.subLicUsageLoaded
          ? "N/A"
          : ""
        : this.getLicUsage[0].expirationCount;
    },
    usersTotal(): any {
      return this.userInfo
        ? this.userInfo.usersTotal != undefined
          ? this.userInfo.usersTotal
          : "N/A"
        : "";
    },
    usersActive(): any {
      return this.userInfo
        ? this.userInfo.usersActive != undefined
          ? this.userInfo.usersActive
          : "N/A"
        : "";
    },
    usersInactive(): any {
      return this.userInfo
        ? this.userInfo.usersInactive != undefined
          ? this.userInfo.usersInactive
          : "N/A"
        : "";
    },
    loadingFinished(): boolean {
      return this.companyUserCountLoaded && this.subLicUsageLoaded;
    },
  },
  data() {
    return {
      prodTypes: ["product", "subscription", "users"],
      selectedProduct: {},
      companyUserCountLoaded: false,
      subLicUsageLoaded: false,
    };
  },
  methods: {
    showAddProducts() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "productAccount");
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, true);
    },
    assignUsers() {
      const task = "add";
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, task);
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, true);
    },
    getCompanyUserCount(companyBucketId: string) {
      if (useIsAuthenticated().value) {
        this.setCompanyUserCountLoaded(false);
        const apiService = new APIService(this.axios);
        apiService
          .getCompanyUserCount(companyBucketId)
          .then((res) => {
            this.$store.dispatch(
              CompanyActionTypes.SET_COMPANY_USERCOUNT,
              res.items
            );
            this.setCompanyUserCountLoaded(true);
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
          });
      }
    },
    getSubLicUsage(companyBucketId: string) {
      if (useIsAuthenticated().value) {
        this.setSubLicUsageLoaded(false);
        const apiService = new APIService(this.axios);
        apiService
          .getSubLicUsage(companyBucketId)
          .then((res) => {
            this.$store.dispatch(CompanyActionTypes.SET_LIC_USAGE, res.items);
            this.setSubLicUsageLoaded(true);
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
          });
      }
    },
    emitLoadingStatus() {
      this.$emit("dashboardCardsLoaded", this.loadingFinished);
    },
    closeModal() {
      this.selectedProduct = {};
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, false);
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, []);
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "none");
      this.$store.dispatch(ProductActionTypes.SET_AVAILABLE_PRODUCTS, {
        items: [],
      });
      this.$store.dispatch(
        UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
        {}
      );
    },
    setCompanyUserCountLoaded(status: boolean) {
      this.companyUserCountLoaded = status;
      if (this.$route.name === "account") {
        this.emitLoadingStatus();
      }
    },
    setSubLicUsageLoaded(status: boolean) {
      this.subLicUsageLoaded = status;
      if (this.$route.name === "account") {
        this.emitLoadingStatus();
      }
    },
  },
  props: {
    showHeader: Boolean,
    dashboardCardsLoaded: Function,
  },
});
</script>
