export enum FlowActionTypes {
  SET_SHOW_DETAILS = "SET_SHOW_DETAILS",
  SET_SHOW_ADDUSER = "SET_SHOW_ADDUSER",
  SET_SELECTED_EMAILS = "SET_SELECTED_EMAILS",
  SET_SELECTED_PRODUCTS = "SET_SELECTED_PRODUCTS",
  SET_PRODUCT_DATA = "SET_PRODUCT_DATA",
  SET_USER_DATA = "SET_USER_DATA",
  SET_SHOW_ADDPRODUCTS = "SET_SHOW_ADDPRODUCTS",
  SET_SUBSCRIPTION_DATA = "SET_SUBSCRIPTION_DATA",
  SET_TASK_TYPE = "SET_TASK_TYPE",
  SET_SELECTED_USERS = "SET_SELECTED_USERS",
  SET_SELECTED_USERIDS = "SET_SELECTED_USERIDS",
  SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
  SET_SEARCH_VALUE = "SET_SEARCH_VALUE",
  SET_SHOWHEADER_SIDEBAR = "SET_SHOWHEADER_SIDEBAR",
  SET_ADDON_DATA = "SET_ADDON_DATA",
  SET_ADDON_USERS = "SET_ADDON_USERS",
  SET_ADDON_PRODUCTS = "SET_ADDON_PRODUCTS",
  SET_SHOW_ASSIGNADDON = "SET_SHOW_ASSIGNADDON",
  SET_SHOW_REMOVEADDON = "SET_SHOW_REMOVEADDON",
  SET_SHOW_REMOVEENTITLEMENT = "SET_SHOW_REMOVEENTITLEMENT",
}
