<template>
  <div class="controltextstyle">
    <h5 class="font-bold-24">Available Users</h5>
    <div class="utils">
      <el-input
        id="usersearch"
        v-model="usrSearchVal"
        placeholder="Search"
        type="search"
        :debounce="1000"
        class="rightmargin inpstyle"
        v-if="showOptions"
      >
        <template #suffix>
          <img src="@/assets/icons/search.svg" style="width: 25px" />
        </template>
      </el-input>
      <el-button plain class="delbtn" @click="addUserHandler" id="addUser">
        <img src="@/assets/icons/adduser.svg" style="width: 40px" />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="assignAddon"
        id="addignaddon"
        v-if="showOptions && !disableAssignAddon"
        :disabled="!enablebuttons"
      >
        <img
          src="@/assets/icons/addaddonenabled.svg"
          style="width: 40px"
          v-if="enablebuttons && !disableAssignAddon"
        />
        <img
          src="@/assets/icons/addaddondisabled.svg"
          style="width: 40px"
          v-if="!enablebuttons"
        />
      </el-button>
      <el-tooltip
        class="box-item"
        effect="dark"
        placement="top"
        v-if="showOptions && disableAssignAddon"
      >
        <template #content>
          Some selected users do not have any <br />products assigned.
        </template>
        <el-button
          plain
          class="delbtn"
          id="addignaddondisabled"
          :disabled="disableAssignAddon"
        >
          <img src="@/assets/icons/addontooltipwarn.svg" style="width: 40px" />
        </el-button>
      </el-tooltip>

      <el-button
        plain
        class="delbtn"
        @click="removeAddon"
        id="removeaddon"
        v-if="showOptions"
        :disabled="!enablebuttons && DisableDeleteAddon"
      >
        <img
          src="@/assets/icons/deladdonenabled.svg"
          style="width: 40px"
          v-if="enablebuttons && !DisableDeleteAddon"
        />
        <img
          src="@/assets/icons/deladdondisabled.svg"
          style="width: 40px"
          v-if="enablebuttons && DisableDeleteAddon"
        />
        <img
          src="@/assets/icons/deladdondisabled.svg"
          style="width: 40px"
          v-if="!enablebuttons"
        />
      </el-button>
      <el-button
        plain
        class="delbtn"
        @click="showDeleteDailogue = true"
        id="deleteuser"
        v-if="showOptions"
        :disabled="!enablebuttons"
      >
        <img
          src="@/assets/icons/delete.svg"
          style="width: 40px"
          v-if="enablebuttons"
        />
        <img
          src="@/assets/icons/deletedisabled.svg"
          style="width: 40px"
          v-if="!enablebuttons"
        />
      </el-button>
    </div>
  </div>
  <el-dialog
    title="Delete Users"
    v-model="showDeleteDailogue"
    width="35%"
    top="25vh"
    center
  >
    <el-divider></el-divider>
    <div class="text-div font-regular-16">
      <span
        ><strong>{{ usersSelectedCount }}</strong> Users selected
      </span>
      <br />
      <span>All user data and subscriptions will be permanently removed.</span>
      <span>This action may not be undone.</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          id="deleteusercancel"
          @click="showDeleteDailogue = false"
          round
          class="cancelbtn"
          variant="outline-dark"
          >Cancel</el-button
        >
        <el-button
          type="danger"
          @click="deleteUser"
          round
          class="deletebutton"
          id="deleteuserconfirm"
        >
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-drawer
    v-model="showAdduser"
    direction="rtl"
    size="40%"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeModal"
  >
    <AddUserModal :showheading="true" />
  </el-drawer>
  <el-drawer
    v-model="showRemoveAddon"
    direction="rtl"
    size="40%"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeModal"
  >
    <RemoveAddonsModal />
  </el-drawer>
  <el-drawer
    v-model="showAssignAddon"
    direction="rtl"
    size="40%"
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :wrapperClosable="false"
  >
    <AssignMultipleAddonsModal :intersectionList="productsIntersectionList" />
  </el-drawer>
</template>

<style lang="scss"></style>
<script lang="ts">
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import APIService from "@/utils/APIService";
import AddUserModal from "@/components/AddUserModal.vue";
import AssignMultipleAddonsModal from "./AssignMultipleAddonsModal.vue";
import RemoveAddonsModal from "./RemoveAddonsModal.vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";

export default defineComponent({
  name: "UserOptions",
  components: {
    AddUserModal,
    AssignMultipleAddonsModal,
    RemoveAddonsModal,
  },
  computed: {
    ...mapGetters([
      "getUsersList",
      "getShowAdduser",
      "getCurrentUser",
      "getSlectedEmails",
      "getCurrentPage",
      "getSearchValue",
      "getAddonUsers",
      "getSelectedUsers",
      "getShowAssignAddon",
      "getShowRemoveAddon",
    ]),
    currentPage(): number {
      return this.getCurrentPage;
    },
    currentUserRole(): string {
      return _.isEmpty(this.getCurrentUser)
        ? ""
        : this.getCurrentUser.result.currentrole;
    },
    showAdduser: {
      get(): boolean {
        return this.getShowAdduser;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      },
    },
    showAssignAddon: {
      get(): boolean {
        return this.getShowAssignAddon;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_ASSIGNADDON, false);
      },
    },
    showRemoveAddon: {
      get(): boolean {
        return this.getShowRemoveAddon;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOW_REMOVEADDON, false);
      },
    },
    usrSearchVal: {
      get(): string {
        return this.getSearchValue === ""
          ? this.usrSearcher
          : this.getSearchValue;
      },
      set(val: string) {
        this.usrSearcher = val;
      },
    },
    showOptions(): boolean {
      return !!(!_.isEmpty(this.getUsersList) || this.usrSearcher !== "");
    },
    DisableDeleteAddon(): boolean {
      return this.getAddonUsers.length === 0;
    },
    disableAssignAddon(): boolean {
      if (
        this.getSelectedUsers !== undefined &&
        this.getSelectedUsers.length !== 0
      )
        this.checkIntersectionOfProducts();
      return (
        this.getSelectedUsers !== 0 &&
        this.productsIntersectionList.length === 0
      );
    },
    enablebuttons(): boolean {
      return this.getSlectedEmails.length !== 0;
    },
    usersSelectedCount(): number {
      return this.getSlectedEmails.length || 0;
    },
  },
  data() {
    return {
      usrSearcher: "",
      showDeleteDailogue: false,
      productsIntersectionList: [] as any[],
      noProducts: false,
    };
  },
  watch: {
    usrSearcher(newVal) {
      const userDataObj = {
        userBucketIdList: null,
        companyBucketIdList: null,
        searchPhrase: newVal || null,
        companyName: null,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        },
      };
      this.$store.dispatch(FlowActionTypes.SET_SEARCH_VALUE, newVal);
      this.$store.dispatch(FlowActionTypes.SET_CURRENT_PAGE, 1);
      const apiService = new APIService(this.axios);
      if (newVal.length !== 0) {
        this.checkSearchStr(userDataObj);
      } else {
        userDataObj.searchPhrase = null;
        apiService.getUsersList(userDataObj).then((res) => {
          this.$store.dispatch(UserActionTypes.SET_USERS, res);
        });
      }
    },
  },
  methods: {
    checkSearchStr: _.debounce(function (this: any, dataobject: any) {
      const apiService = new APIService(this.axios);
      apiService.getUsersList(dataobject).then((res) => {
        this.$store.dispatch(UserActionTypes.SET_USERS, res);
      });
    }, 1000),
    addUserHandler() {
      // const task = this.$route.name === "user" ? "add" : "assign";
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "add");
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, true);
    },
    checkIntersectionOfProducts() {
      const productsCollection: { [key: string]: any[] } = {};
      this.getSelectedUsers.map((item: any, id: number) => {
        productsCollection[`array${id}`] = item.products;
      });
      console.log(productsCollection);
      const productArray = Object.values(productsCollection);
      this.productsIntersectionList = productArray.reduce(
        (acc, currentArray) => this.arrayIntersection(acc, currentArray),
        productArray[0]
      );
      console.log(this.productsIntersectionList);
    },
    arrayIntersection(arr1: number[], arr2: number[]): number[] {
      return arr1.filter((item) => arr2.includes(item));
    },
    deleteUser() {
      const apiService = new APIService(this.axios);
      const emailData = {
        UserBucketIdCollection: this.getSlectedEmails,
      };
      apiService.deleteUsers(emailData).then((res) => {
        const userDataObj = {
          userBucketIdList: null,
          companyBucketIdList: null,
          searchPhrase: this.usrSearcher,
          companyName: null,
          pageOptions: {
            skip: this.currentPage - 1,
            take: 10,
            sortField: null,
            sortOrder: null,
          },
        };

        if (res.success) {
          apiService.getUsersList(userDataObj).then((resp) => {
            this.$store.dispatch(UserActionTypes.SET_USERS, resp);
          });
          this.$store.dispatch(FlowActionTypes.SET_SELECTED_EMAILS, []);
          this.showDeleteDailogue = false;
          ElMessage({
            message: "User has been deleted successfully.",
            type: "success",
          });
        } else {
          ElMessage.error(res.validationResult.validationMessages[0]);
        }
      });
    },
    assignAddon() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "assignmultiaddons");
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ASSIGNADDON, true);
    },
    removeAddon() {
      this.$store.dispatch(FlowActionTypes.SET_SHOW_REMOVEADDON, true);
    },
    closeModal() {
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, false);
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, []);
      if (this.$route.name !== "product")
        this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, {});
      // ^ otherwise user is sent back to products overview page instead of details page
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "none");
      this.$store.dispatch(ProductActionTypes.SET_AVAILABLE_PRODUCTS, {
        items: [],
      });
      this.$store.dispatch(FlowActionTypes.SET_ADDON_DATA, []);
      this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, {});
      this.$store.dispatch(
        UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
        {}
      );
    },
  },
  props: {
    sortField: Number,
    sortOrder: Number,
  },
});
</script>
