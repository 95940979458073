<template>
  <el-table
    id="assignedproductstable"
    :data="productsList"
    style="width: 100%"
    :header-cell-style="headerstyle"
    :row-style="rowdatastyle"
    @selection-change="handleSelectionChange"
    empty-text="No products assigned"
    >>
    <el-table-column type="selection" width="55"> </el-table-column>
    <el-table-column label="Assigned Products" prop="productName">
      <template #default="props">
        <div class="prodcelldiv">
          <img src="@/assets/icons/vcicon.svg" class="cellimage" />
          <div class="celltext">
            <label style="margin: unset">{{
              props.row.productName.substring(0, 18)
            }}</label>
            <label style="font-weight: 700">{{
              props.row.productName.substring(18)
            }}</label>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" label="Version" width="80">
      <template #default="props">
        {{ props.row.majorVersionNumber }}.{{ props.row.minorVersionNumber }}
      </template>
    </el-table-column>
    <el-table-column align="center" label="License Type" prop="licenseType">
    </el-table-column>
    <el-table-column label="Computer ID" prop="computer"> </el-table-column>
    <el-table-column label="Product Status" prop="support" align="center">
      <template #default="prod">
        <div class="borrowcell">
          <img
            src="@/assets/icons/active.svg"
            v-if="prod.row.active === true"
          />
          <img
            src="@/assets/icons/inactive.svg"
            v-if="prod.row.active === false"
          />
          <!-- <img src="@/assets/icons/pending.png"
                v-if="prod.row.support === 'pending'" /> -->
          <label style="margin-left: 5px" v-if="prod.row.active === true"
            >In Use</label
          >
          <label style="margin-left: 5px" v-if="prod.row.active === false"
            >Not Used</label
          >
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Add-on" align="center" prop="addOns">
      <template #default="props">
        <div v-for="(addon, index) in props.row.addOns" :key="index">
          {{ addon.productName }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="Expiration Date"
      prop="expirationDate"
      align="center"
    >
    </el-table-column>
    <el-table-column
      label="Maintenance Renewal Date"
      prop="maintenanceRenewalDate"
    >
    </el-table-column>
  </el-table>
</template>

<style lang="scss">
.prodcelldiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";

export default defineComponent({
  name: "AssignedProducts",
  components: {},
  computed: {
    ...mapGetters(["getUserData", "getAssProdList"]),
    productsList(): any {
      return this.getAssProdList.items;
    },
  },
  data() {
    return {
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      multipleSelection: [],
    };
  },
  methods: {
    getAssignedProducts() {
      const apiService = new APIService(this.axios);
      const userDataObj = {
        userBucketIdList: [this.getUserData.userBucketId],
        pageOptions: {
          sortOrder: 0,
        },
      };
      apiService
        .getAssignedProdsList(userDataObj)
        .then((res) => {
          this.$store.dispatch(UserActionTypes.SET_ASSIGNED_PRODUCTS, res);
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED")
            this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
        });
    },
    handleSelectionChange(selected: any) {
      this.multipleSelection = selected;
      var addonProducts = [] as any[];
      selected.map((item: any) => {
        if (item.addOns.length !== 0) addonProducts = item.addOns;
      });
      this.$store.dispatch(FlowActionTypes.SET_ADDON_PRODUCTS, addonProducts);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_PRODUCTS, selected);
    },
  },
  mounted() {
    this.getAssignedProducts();
  },
});
</script>
