<template>
  <el-menu
    mode="vertical"
    :ellipsis="false"
    menu-trigger="click"
    default-active="/account"
    @select="handleSelect"
  >
    <div class="sidebardiv">
      <el-menu-item
        index="/account"
        class="sidebarmenulabel"
        @click="tabClicked"
      >
        Overview
      </el-menu-item>
      <el-menu-item
        index="/products"
        class="sidebarmenulabel"
        @click="tabClicked"
      >
        Products &#38; Services
      </el-menu-item>
      <el-menu-item index="/users" class="sidebarmenulabel" @click="tabClicked">
        Users
      </el-menu-item>
      <el-menu-item
        index="/companies"
        class="sidebarmenulabel"
        @click="tabClicked"
      >
        <!--v-if="userRole !== 'LicenseAdmin'" ** Removed validation as per discussion for Early Access **  -->
        Companies
      </el-menu-item>
    </div>
    <el-divider />
    <div class="sidebarnamediv">
      <el-button circle class="namebtn topmargin bottommargin" type="warning">
        {{ nameInitials }}
      </el-button>
      <label class="complabel bottommargin" style="margin-bottom: 0">
        {{ currentUserName.firstName }}
        {{ currentUserName.lastName }}
      </label>
    </div>
    <el-divider />
    <el-menu-item index="4" class="sidebarmenufont" @click="onmyaccount">
      My Profile
    </el-menu-item>
    <el-divider />
    <el-menu-item
      index="5"
      class="sidebarmenufont"
      id="logout"
      @click="onlogout"
    >
      Logout
    </el-menu-item>
    <UsefullLinks />
  </el-menu>
</template>
<style lang="scss">
.sidebardiv {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}
.complabel {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  word-break: break-word;
  overflow-wrap: break-word;
}
.sidebarnamediv {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.el-menu {
  border-right: unset;
}
.sidebarmenufont {
  color: #101010;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  // overflow-wrap: break-word;
  // word-wrap: break-word;
  width: 250px;
}
.namebtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: #101010;
  background: #ffbd2c;
  border: 2px solid #ffbd2c;
  min-height: 2.5rem;
  width: 2.5rem !important;
}
.sidebarmenulabel {
  color: #101010;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  min-width: 12rem;
  align-items: center;
}
.el-menu-item {
  padding: 0 25px 0 10px !important;
}

.el-menu-item-group {
  display: flex;
}
.el-menu-item.is-active {
  border-bottom: 4px solid #ffbd2c !important;
}

.el-divider--horizontal {
  margin-bottom: unset !important;
  margin-top: 10px !important;
}

.el-menu--popup {
  padding: unset !important;
}

.el-icon--right {
  margin: unset !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { RouteLocationRaw } from "vue-router";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
//import SSOAuth from "@/utils/SSOAuth";
import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import UsefullLinks from "./UsefullLinks.vue";
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";
import { msalInstance } from "@/authConfig";

export default defineComponent({
  name: "HeaderSideBar",
  components: {
    UsefullLinks,
  },
  data() {
    return {
      nameInitials: "",
      userRole: "",
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getShowSideHeader"]),
    currentUserName(): any {
      this.updateUserData();
      if (this.nameInitials === "") this.setInitials();
      return _.isEmpty(this.getCurrentUser) ? "" : this.getCurrentUser.result;
    },
    ShowSideHeader: {
      get(): boolean {
        return this.getShowSideHeader;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SHOWHEADER_SIDEBAR, false);
      },
    },
    activeIndex(): any {
      return this.$route.path;
    },
  },
  methods: {
    async onlogout() {
      await msalInstance.logoutRedirect();
    },
    processLogout() {
      this.$store.dispatch(UserActionTypes.SET_USER_ERROR, false);
      this.$store.dispatch(AuthActionTypes.LOGOUT);
    },
    updateUserData() {
      if (useIsAuthenticated().value && _.isEmpty(this.getCurrentUser)) {
        const apiService = new APIService(this.axios);
        apiService
          .getCurrentUser()
          .then((res: any) => {
            if (!_.isEmpty(res) && res.result.currentRole !== "User") {
              this.$store.dispatch(UserActionTypes.SET_CURRENTUSER, res);
              this.userRole = res.result.currentRole;
            } else {
              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
              const myproduct = process.env.VUE_APP_MY_PRODUCTS!;
              // const muproducturl = `${myproduct}?r=${window.location}`;
              window.location.replace(myproduct);
            }
          })
          .catch((err) => {
            if (err.code.includes("ERR_BAD")) this.onlogout();
            else if (err.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
            else this.$store.dispatch(UserActionTypes.SET_USER_ERROR, true);
          });
      }
    },
    setInitials() {
      this.nameInitials =
        !_.isEmpty(this.getCurrentUser) &&
        this.getCurrentUser.result.firstName &&
        this.getCurrentUser.result.lastName
          ? this.getCurrentUser.result.firstName.substring(0, 1).toUpperCase() +
            this.getCurrentUser.result.lastName.substring(0, 1).toUpperCase()
          : "";
    },
    showHeaderSideBar() {
      this.$store.dispatch(FlowActionTypes.SET_SHOWHEADER_SIDEBAR, true);
    },
    tabClicked() {
      this.$store.dispatch(FlowActionTypes.SET_USER_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, {});
      this.$store.dispatch(CompanyActionTypes.SET_COMPANY_DATA, {});
      this.$store.dispatch(CompanyActionTypes.SET_LIC_USAGE, {});
      this.$store.dispatch(CompanyActionTypes.SET_COMPANY_USERCOUNT, {});
      this.$store.dispatch(FlowActionTypes.SET_SEARCH_VALUE, "");
      this.$store.dispatch(UserActionTypes.SET_ASSIGNED_PRODUCTS, {});
      this.$store.dispatch(CompanyActionTypes.SET_COMPANIES, {});
    },
    onmyaccount() {
      sessionStorage.setItem("BACK_URL", window.location.href);
      const myaccount = process.env.VUE_APP_MY_ACCOUNT_SERVER;
      const myaccountParams = `${myaccount}?r=${window.location}`;
      window.location.href = myaccountParams;
    },
    handleSelect(key: RouteLocationRaw) {
      this.$store.dispatch(FlowActionTypes.SET_SHOWHEADER_SIDEBAR, false);
      if (key !== "2-1") this.$router.push(key);
    },
  },
});
</script>
