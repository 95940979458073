import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailsFlow = _resolveComponent("DetailsFlow")!
  const _component_CompaniesOverview = _resolveComponent("CompaniesOverview")!
  const _component_CompanyDeatils = _resolveComponent("CompanyDeatils")!
  const _component_SubscriptionDetails = _resolveComponent("SubscriptionDetails")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DetailsFlow, {
      overview: "Companies",
      detail: "Company"
    }),
    (_ctx.showDetails && !_ctx.showCompanySubDetails)
      ? (_openBlock(), _createBlock(_component_CompaniesOverview, { key: 0 }))
      : _createCommentVNode("v-if", true),
    (!_ctx.showDetails && !_ctx.showCompanySubDetails)
      ? (_openBlock(), _createBlock(_component_CompanyDeatils, { key: 1 }))
      : _createCommentVNode("v-if", true),
    (_ctx.showCompanySubDetails)
      ? (_openBlock(), _createBlock(_component_SubscriptionDetails, { key: 2 }))
      : _createCommentVNode("v-if", true)
  ]))
}