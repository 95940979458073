<template>
  <el-table
    id="companysubscriptionstable"
    v-loading="loading"
    :data="subscriptionList"
    style="width: 100%"
    max-height="600px"
    scrollbar-always-on
    :header-cell-style="headerstyle"
    :header-row-style="headerrow"
    :row-style="rowdatastyle"
    empty-text="No subscriptions available"
  >
    <el-table-column label="Product Name" prop="productName">
      <template #default="props">
        <div class="celldiv">
          <img src="@/assets/icons/vcicon.svg" class="cellimage" />
          <div :class="props.row.expirationCount > 0 ? 'expirationlabel' : ''">
            {{ props.row.productName }}
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" label="Version">
      <template #default="props">
        {{ props.row.majorVersionNumber }}.{{ props.row.minorVersionNumber }}
      </template>
    </el-table-column>
    <el-table-column label="Product Seats" align="center">
      <template #default="props">
        {{ props.row.seatsUsed }}/{{ props.row.seats }}
      </template>
    </el-table-column>
    <el-table-column label="Add-on" align="center" prop="addOns">
      <template #default="props">
        <div v-for="(addon, index) in props.row.addOns" :key="index">
          {{ addon.productName }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Add-on Seats" align="center">
      <template #default="props">
        <div v-for="(addon, index) in props.row.addOns" :key="index">
          {{ addon.seatsUsed }}/{{ addon.seats }}
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" label="" width="120">
      <template #default="subcription">
        <el-button @click="viewDetails(subcription.row)" link size="small">
          <img src="@/assets/icons/openlistdetails.svg" style="width: 25px" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <div class="usermodalpagingdiv" v-if="totalPages > 1">
    <label class="pagelabel"
      ><strong>Page {{ currentPage }} / {{ totalPages }}</strong></label
    >
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = 1"
      id="firstpage"
      :disabled="currentPage === 1"
    >
      <img
        src="@/assets/icons/pagefirstenabled.svg"
        style="width: 20px"
        v-if="currentPage !== 1"
      />
      <img
        src="@/assets/icons/pagefirstdisabled.svg"
        style="width: 20px"
        v-if="currentPage === 1"
      />
    </el-button>
    <el-pagination
      layout="prev, next"
      :total="totalRowsCount"
      :page-size="10"
      v-model:current-page="currentPage"
    />
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = totalPages"
      id="lastpage"
      :disabled="currentPage === totalPages"
    >
      <img
        src="@/assets/icons/pagelastenabled.svg"
        style="width: 20px"
        v-if="currentPage !== totalPages"
      />
      <img
        src="@/assets/icons/pagelastdisabled.svg"
        style="width: 20px"
        v-if="currentPage === totalPages"
      />
    </el-button>
  </div>
  <el-drawer
    v-model="showSubscriptionDetail"
    direction="rtl"
    size="40%"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <SubscriptionDetailModal />
  </el-drawer>
</template>

<style lang="scss">
.expirationlabel {
  color: #df444d;
  font-size: 14px;
  line-height: 1.53em;
  font-family: "Open Sans";
  font-weight: 400;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import APIService from "@/utils/APIService";
import _ from "lodash";
import { mapGetters } from "vuex";
import SubscriptionDetailModal from "@/components/SubscriptionDetailModal.vue";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { ProductInfomation } from "@/utils/AppInterfaces";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";

export default defineComponent({
  name: "CompanySubscriptions",
  components: {
    SubscriptionDetailModal,
  },
  computed: {
    ...mapGetters([
      "getCompanySubscriptions",
      "getShowSubscriptionDetails",
      "getCompanyData",
    ]),
    subscriptionList(): any {
      return this.getCompanySubscriptions.items;
    },
    showSubscriptionDetail: {
      get(): boolean {
        return this.getShowSubscriptionDetails;
      },
      set() {
        this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, {});
        this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, []);
        this.$store.dispatch(
          ProductActionTypes.SET_SHOW_SUBSCRIPTIONDETAILS,
          false
        );
      },
    },
  },
  data() {
    return {
      newprodList: [],
      loading: true,
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      headerrow: {
        background: "#F2F2F2",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      currentPage: 1,
      perPage: 10,
      pagesNumber: 1,
      totalPages: 0,
      totalRowsCount: 0,
    };
  },
  watch: {
    currentPage: function watchForChanges(currPage, prevPage) {
      if (currPage !== prevPage) {
        this.loading = true;
        const apiService = new APIService(this.axios);
        const companyDataObj = {
          companyBucketIdCollection: [
            !_.isEmpty(this.getCompanyData)
              ? this.getCompanyData.companyBucketId
              : "",
          ],
          pageOptions: {
            skip: this.currentPage - 1,
            take: 10,
            sortField: 11,
            sortOrder: 0,
          },
        };
        apiService
          .getSubscriptionsListWithCompanyBucketId(companyDataObj)
          .then((res) => {
            this.loading = false;
            this.$store.dispatch(
              CompanyActionTypes.SET_COMPANY_SUBSCRIPTIONS,
              res
            );
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
          });
      }
    },
  },
  methods: {
    getSubscriptions() {
      const apiService = new APIService(this.axios);
      const companyDataObj = {
        companyBucketIdCollection: [
          !_.isEmpty(this.getCompanyData)
            ? this.getCompanyData.companyBucketId
            : "",
        ],
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: 11,
          sortOrder: 0,
        },
      };
      apiService
        .getSubscriptionsListWithCompanyBucketId(companyDataObj)
        .then((res) => {
          this.$store.dispatch(
            CompanyActionTypes.SET_COMPANY_SUBSCRIPTIONS,
            res
          );
          this.updatePaginationData(res.pagingData);
          this.loading = false;
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED")
            this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
        });
    },
    updatePaginationData(pagingData: any) {
      this.currentPage =
        this.currentPage !== 0 ? this.currentPage : pagingData.index;
      this.totalPages = pagingData.totalPages;
      this.totalRowsCount = pagingData.itemCount;
    },
    async viewDetails(sub: ProductInfomation) {
      // this.$store.dispatch(
      //   ProductActionTypes.SET_SHOW_SUBSCRIPTIONDETAILS,
      //   true
      // );
      await this.$store.dispatch(FlowActionTypes.SET_SUBSCRIPTION_DATA, sub);
    },
  },
  mounted() {
    this.getSubscriptions();
  },
});
</script>
