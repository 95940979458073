<template>
  <el-table
    v-loading="loading"
    ref="assignedUserstbl"
    id="assignedusertable"
    :data="usersList"
    max-height="500"
    scrollbar-always-on
    :header-cell-style="headerstyle"
    :row-style="rowdatastyle"
    @selection-change="handleSelectionChange"
    :row-class-name="rowDisableWithStatus"
    :cell-class-name="disableCellWithCheckedout"
    empty-text="No users assigned"
    v-if="showSubscription"
    class="topmargin"
  >
    <el-table-column type="selection" width="50"> </el-table-column>
    <el-table-column label="User Name">
      <template #default="scope">
        <el-tooltip placement="top" v-if="scope.row.status === 'Checked-out'">
          <template #content
            >The license is checked out, so cannot remove assignment
          </template>
          <div>
            {{ scope.row.userName }}
          </div>
        </el-tooltip>
        <div v-if="scope.row.status !== 'Checked-out'">
          {{ scope.row.userName }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Email" prop="email"> </el-table-column>
    <el-table-column
      align="center"
      label="Subscription End Date"
      prop="expirationDate"
    >
    </el-table-column>
    <el-table-column label="Add-on" align="center" prop="addOns">
      <template #default="props">
        <div v-for="(addon, index) in props.row.addOns" :key="index">
          {{ addon.productName }}
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" label="Status" prop="status">
      <template #default="user">
        <div class="borrowcell">
          <img
            src="@/assets/icons/borrowed.svg"
            style="width: 25px"
            v-if="user.row.status === 'Checked-out'"
          />
          <img
            src="@/assets/icons/assigned.svg"
            style="width: 25px"
            v-if="user.row.status === 'Assigned'"
          />
          <label v-if="user.row.status === 'Checked-out'"> Borrowed</label>
          <label v-if="user.row.status === 'Assigned'">
            {{ user.row.status }}</label
          >
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagingdiv" v-if="totalPages > 1">
    <label class="pagelabel"
      ><strong>Page {{ currentPage }} / {{ totalPages }}</strong></label
    >
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = 1"
      id="fiestpage"
      :disabled="currentPage === 1"
    >
      <img
        src="@/assets/icons/pagefirstenabled.svg"
        style="width: 20px"
        v-if="currentPage !== 1"
      />
      <img
        src="@/assets/icons/pagefirstdisabled.svg"
        style="width: 20px"
        v-if="currentPage === 1"
      />
    </el-button>
    <el-pagination
      layout="prev, next"
      :total="totalRowsCount"
      :page-size="10"
      v-model:current-page="currentPage"
    />
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = totalPages"
      id="lastpage"
      :disabled="currentPage === totalPages"
    >
      <img
        src="@/assets/icons/pagelastenabled.svg"
        style="width: 20px"
        v-if="currentPage !== totalPages"
      />
      <img
        src="@/assets/icons/pagelastdisabled.svg"
        style="width: 20px"
        v-if="currentPage === totalPages"
      />
    </el-button>
  </div>
</template>

<style lang="scss">
.el-table .disabled-row {
  background-color: rgba(172, 179, 180, 0.5);
  cursor: not-allowed;
}
.el-table .disabled-cell {
  cursor: not-allowed;
}
.el-table .disabled-row .el-checkbox__input .el-checkbox__inner {
  cursor: not-allowed;
  background-color: rgba(172, 179, 180, 0.5);
  border-color: rgba(172, 179, 180, 0.5);
}
.el-table .cell {
  padding: 12px !important;
}
.borrowcell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-button.paginationbtn {
  color: #101010;
  width: 32px;
  height: 32px;
  // margin-right: 10px;
  padding: unset;
  // border: #ffffff !important;
  border: 1px solid #dee2e6;
}
.paginationbtn.is-disabled {
  color: #101010 !important;
  background: #ffffff !important;
  // border: 2px solid #ffffff !important;
  border: 1px solid #dee2e6 !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";

export default defineComponent({
  name: "SubscriptionAssignedUsers",
  computed: {
    ...mapGetters([
      "getProductData",
      "getSubscriptionData",
      "getAssignedUserList",
      "getCurrentPage",
      "getCompanyData",
    ]),
    usersList(): any {
      if (!_.isEmpty(this.getAssignedUserList)) {
        this.updatePaginationData();
      }
      return this.getAssignedUserList.items;
    },
    currentPage: {
      get(): any {
        return this.getCurrentPage;
      },
      set(currPage: number) {
        this.$store.dispatch(FlowActionTypes.SET_CURRENT_PAGE, currPage);
      },
    },
  },
  data() {
    return {
      showSubscription: false,
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      indexList: [],
      allSelected: false,
      totalRows: 1,
      perPage: 10,
      // currentPage: 1,
      pagesNumber: 1,
      totalPages: 0,
      totalRowsCount: 0,
      itemsOnPage: 0,
      loading: true,
      userHasAddon: false,
    };
  },
  watch: {
    currentPage: function watchForChanges(currPage, prevPage) {
      if (currPage !== prevPage) {
        this.loading = true;
        const apiService = new APIService(this.axios);
        if (!_.isEmpty(this.getProductData)) {
          apiService
            .getAssignedUsersList(
              this.getProductData.productBucketId,
              null,
              currPage - 1,
              10
            )
            .then((res) => {
              this.loading = false;
              this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res);
            })
            .catch((error) => {
              if (error.code === "ECONNABORTED")
                this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
            });
        } else if (!_.isEmpty(this.getSubscriptionData)) {
          this.showSubscription = true;
          const subData = {
            Searchphrase: "",
            productBucketId: this.getSubscriptionData.productBucketId,
            companyBucketId:
              this.$route.name === "companies"
                ? this.getCompanyData.companyBucketId
                : null,
            Major: this.getSubscriptionData.majorVersionNumber,
            Minor: this.getSubscriptionData.minorVersionNumber,
            Patch: this.getSubscriptionData.patchVersionNumber,
            pageOptions: {
              skip: currPage - 1,
              take: 10,
              sortField: null,
              sortOrder: null,
            },
          };
          apiService
            .getAssignedUsersSubList(subData)
            .then((res) => {
              this.loading = false;
              this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res);
            })
            .catch((error) => {
              if (error.code === "ECONNABORTED")
                this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
            });
        }
        this.$store.dispatch(FlowActionTypes.SET_CURRENT_PAGE, currPage);
      }
    },
  },
  methods: {
    getAssignedUsers() {
      const apiService = new APIService(this.axios);
      if (!_.isEmpty(this.getProductData)) {
        apiService
          .getAssignedUsersList(
            this.getProductData.productBucketId,
            null,
            this.currentPage - 1,
            10
          )
          .then((res) => {
            this.loading = false;
            this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res);
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
          });
      } else if (!_.isEmpty(this.getSubscriptionData)) {
        this.showSubscription = true;
        const subData = {
          Searchphrase: "",
          productBucketId: this.getSubscriptionData.productBucketId,
          companyBucketId:
            this.$route.name === "companies"
              ? this.getCompanyData.companyBucketId
              : null,
          Major: this.getSubscriptionData.majorVersionNumber,
          Minor: this.getSubscriptionData.minorVersionNumber,
          Patch: this.getSubscriptionData.patchVersionNumber,
          pageOptions: {
            skip: this.currentPage - 1,
            take: 10,
            sortField: null,
            sortOrder: null,
          },
        };
        apiService
          .getAssignedUsersSubList(subData)
          .then((res) => {
            this.loading = false;
            this.$store.dispatch(ProductActionTypes.SET_ASSIGNED_USERS, res);
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
          });
      }
    },
    handleSelectionChange(selected: any) {
      this.allSelected = selected.length === this.usersList.length;
      const userBucketIds = [] as any;
      const addonUsers = [] as any[];
      const userAddons = [] as any[];
      // eslint-disable-next-line array-callback-return
      selected.map((item: any) => {
        if (item.status !== "Checked-out")
          userBucketIds.push(item.userBucketId);
        if (item.addOns.length !== 0) addonUsers.push(item);
        item.addOns.map((addon: any) => {
          if (!userAddons.some((userAddon) => userAddon === addon.productName))
            userAddons.push(addon);
        });
      });
      this.$store.dispatch(FlowActionTypes.SET_ADDON_USERS, addonUsers);
      this.$store.dispatch(UserActionTypes.SET_USER_ADDONS, userAddons);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, selected);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERIDS, userBucketIds);
    },
    updatePaginationData() {
      this.currentPage =
        this.currentPage !== 0
          ? this.currentPage
          : this.getAssignedUserList.pagingData.currentPage;
      this.totalPages = this.getAssignedUserList.pagingData.totalPages;
      this.totalRowsCount = this.getAssignedUserList.pagingData.itemCount;
    },
    rowDisableWithStatus({ row }: { row: any }) {
      if (row.status === "Checked-out") return "disabled-row";
      return "";
    },
    disableCellWithCheckedout({
      row,
      columnIndex,
    }: {
      row: any;
      columnIndex: number;
    }) {
      if (row.status === "Checked-out" && columnIndex === 0) {
        return "disabled-cell";
      }
      return "";
    },
  },
  mounted() {
    this.getAssignedUsers();
  },
});
</script>
