<template>
  <UserOptions :sortField="sorttingField" :sortOrder="sortOrder" />
  <el-table
    id="userslisttable"
    v-loading="loading"
    :data="userList"
    style="width: 100%"
    max-height="600px"
    scrollbar-always-on
    :header-cell-style="headerstyle"
    :row-style="rowdatastyle"
    @selection-change="handleSelectionChange"
    @row-click="rowClickHandler"
    @sort-change="handleSorting"
    :default-sort="{ prop: 'name', order: 'aescending' }"
    :empty-text="nodatatxt"
  >
    <el-table-column type="selection" width="55"> </el-table-column>
    <el-table-column label="User Name" prop="name" sortable> </el-table-column>
    <el-table-column sortable label="Email" prop="email"> </el-table-column>
    <el-table-column label="Assigned Products" prop="products">
      <template #default="props">
        <div v-for="(prod, index) in props.row.products" :key="index">
          {{ prod.productName }} {{ prod.majorVersionNumber }}.{{
            prod.minorVersionNumber
          }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Assigned Add-ons" align="center" prop="addOns">
      <template #default="props">
        <div v-for="(addon, index) in props.row.addOns" :key="index">
          {{ addon.productName }} {{ addon.majorVersionNumber }}.{{
            addon.minorVersionNumber
          }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="Application Status"
      align="center"
      prop="userStatus"
    >
      <template #default="user">
        <div class="borrowcell">
          <img
            src="@/assets/icons/active.svg"
            style="width: 20px"
            v-if="user.row.userStatus === 'Active'"
          />
          <img
            src="@/assets/icons/idle.svg"
            style="width: 20px"
            v-if="user.row.userStatus === 'Idle'"
          />
          <label style="margin-left: 5px">{{ user.row.userStatus }}</label>
        </div>
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="" width="120">
      <template #default="user">
        <el-button @click="viewDetails(user.row)" plain link size="small">
          <img src="@/assets/icons/openlistdetails.svg" style="width: 25px" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagingdiv" v-if="totalPages > 1">
    <label class="pagelabel"
      ><strong>Page {{ currentPage }} / {{ totalPages }}</strong></label
    >
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = 1"
      id="firstpage"
      :disabled="currentPage === 1"
    >
      <img
        src="@/assets/icons/pagefirstenabled.svg"
        style="width: 20px"
        v-if="currentPage !== 1"
      />
      <img
        src="@/assets/icons/pagefirstdisabled.svg"
        style="width: 20px"
        v-if="currentPage === 1"
      />
    </el-button>
    <el-pagination
      layout="prev, next"
      :total="totalRowsCount"
      :page-size="10"
      v-model:current-page="currentPage"
    />
    <el-button
      plain
      class="paginationbtn"
      @click="currentPage = totalPages"
      id="lastpage"
      :disabled="currentPage === totalPages"
    >
      <img
        src="@/assets/icons/pagelastenabled.svg"
        style="width: 20px"
        v-if="currentPage !== totalPages"
      />
      <img
        src="@/assets/icons/pagelastdisabled.svg"
        style="width: 20px"
        v-if="currentPage === totalPages"
      />
    </el-button>
  </div>
</template>
<style lang="scss">
.usercontent {
  box-sizing: border-box;
  flex-direction: column;
  margin: 0;
  padding: 0 4vw;
}

.el-checkbox__original::placeholder {
  display: none;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import APIService from "@/utils/APIService";
import { UserInformation } from "@/utils/AppInterfaces";
import { mapGetters } from "vuex";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import UserOptions from "./UserOptions.vue";
import _ from "lodash";
import { AuthActionTypes } from "@/store/authstore/AuthActionTypes";

export default defineComponent({
  name: "UsersOverview",
  components: {
    UserOptions,
  },
  computed: {
    ...mapGetters([
      "getUsersList",
      "getCurrentUser",
      "getCurrentPage",
      "getSearchValue",
    ]),
    userList(): any {
      if (!_.isEmpty(this.getUsersList)) {
        this.updatePaginationData();
      }
      return this.getUsersList.items;
    },
    currentRole(): string {
      return this.ctrltxt;
    },
    currentPage: {
      get(): number {
        return this.getCurrentPage;
      },
      set(currPage: number) {
        this.$store.dispatch(FlowActionTypes.SET_CURRENT_PAGE, currPage);
      },
    },
  },
  data() {
    return {
      ctrltxt: "Available Users",
      nodatatxt: "No users available",
      headerstyle: {
        "border-bottom": "unset",
        background: "#F2F2F2",
        "font-family": "Open Sans, sans-serif",
        "font-weight": "700",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
      },
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "#101010",
        cursor: "pointer",
      },
      totalRows: 1,
      perPage: 10,
      pagesNumber: 1,
      totalPages: 0,
      totalRowsCount: 0,
      itemsOnPage: 0,
      loading: true,
      sorttingField: null as any,
      sortOrder: null as any,
      multipleSelection: [],
    };
  },
  watch: {
    currentPage: function watchForChanges(currPage, prevPage) {
      if (currPage !== prevPage) {
        this.loading = true;
        const apiService = new APIService(this.axios);
        const userDataObj = {
          userBucketIdList: null,
          companyBucketIdList: null,
          searchPhrase: this.getSearchValue,
          companyName: null,
          pageOptions: {
            skip: currPage - 1,
            take: 10,
            sortField: this.sorttingField,
            sortOrder: this.sortOrder,
          },
        };

        apiService
          .getUsersList(userDataObj)
          .then((res) => {
            this.$store.dispatch(UserActionTypes.SET_USERS, res);
            this.loading = false;
            this.$store.dispatch(FlowActionTypes.SET_CURRENT_PAGE, currPage);
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED")
              this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
          });
      }
    },
  },
  methods: {
    getUserList() {
      const userDataObj = {
        userBucketIdList: null,
        companyBucketIdList: null,
        searchPhrase: this.getSearchValue,
        companyName: null,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: null,
          sortOrder: null,
        },
      };
      const apiService = new APIService(this.axios);
      apiService
        .getUsersList(userDataObj)
        .then((res) => {
          this.$store.dispatch(UserActionTypes.SET_USERS, res);
          this.loading = false;
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED")
            this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
        });
    },
    updatePaginationData() {
      this.currentPage =
        this.currentPage !== 0
          ? this.currentPage
          : this.getUsersList.pagingData.currentPage;
      this.totalPages = this.getUsersList.pagingData.totalPages;
      this.totalRowsCount = this.getUsersList.pagingData.itemCount;
    },
    handleSelectionChange(selected: any) {
      this.multipleSelection = selected;
      const selectedEmails: any[] = [];
      const addonUsers = [] as any[];
      const userAddons = [] as any[];
      const userBucketIds = [] as any;
      selected.map((item: any) => {
        // this.multipleSelection.push(item);
        selectedEmails.push(item.userBucketId);
        if (item.addOns.length !== 0) {
          addonUsers.push(item);
          userBucketIds.push(item.userBucketId);
        }
        item.addOns.map((addon: any) => {
          if (!userAddons.some((item) => item === addon.name))
            userAddons.push(addon);
        });
      });
      this.$store.dispatch(FlowActionTypes.SET_ADDON_USERS, addonUsers);
      this.$store.dispatch(UserActionTypes.SET_USER_ADDONS, userAddons);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, selected);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_EMAILS, selectedEmails);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERIDS, userBucketIds);
    },
    handleSorting({ prop, order }: { prop: any; order: any }) {
      if (order === null) {
        this.sortOrder = null;
        this.sorttingField = null;
      } else {
        // eslint-disable-next-line no-nested-ternary
        this.sorttingField = prop === "name" ? 1 : prop === "email" ? 3 : 0;
        this.sortOrder = order === "ascending" ? 0 : 1;
      }

      const userDataObj = {
        userBucketIdList: null,
        companyIdList: null,
        searchPhrase: null,
        companyName: null,
        pageOptions: {
          skip: 0,
          take: 10,
          sortField: this.sorttingField,
          sortOrder: this.sortOrder,
        },
      };
      this.$store.dispatch(UserActionTypes.SET_USERS, {});
      this.$store.dispatch(FlowActionTypes.SET_CURRENT_PAGE, 1);
      this.loading = true;
      const apiService = new APIService(this.axios);
      apiService
        .getUsersList(userDataObj)
        .then((res) => {
          this.$store.dispatch(UserActionTypes.SET_USERS, res);
          this.loading = false;
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED")
            this.$store.dispatch(AuthActionTypes.SET_ERROR, true);
        });
    },
    viewDetails(user: UserInformation) {
      const userdata = [];
      userdata.push(user);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, userdata);
      this.$store.dispatch(FlowActionTypes.SET_USER_DATA, user);
    },
    rowClickHandler(record: any) {
      const userdata = [];
      userdata.push(record);
      // this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, record);
      this.$store.dispatch(FlowActionTypes.SET_USER_DATA, record);
    },
  },
  mounted() {
    this.getUserList();
  },
});
</script>
