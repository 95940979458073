<template>
  <h4 class="modal-title" id="assignprodtitle">ASSIGN PRODUCT & ADD-ON</h4>
  <div
    class="modalbody"
    v-if="taskType === 'productAccount' || taskType === 'prodAss'"
  >
    <div class="datadiv bottommargin">
      <label class="headerfont"> Select available product </label>
      <div class="overflowdiv topmargin">
        <el-table
          id="prodtoassigntable"
          v-loading="loading"
          :data="products"
          :border="true"
          style="width: 100%"
          :row-style="rowdatastyle"
          highlight-current-row
          @current-change="handleCurrentChange"
          :row-class-name="tableRowClassName"
          :show-header="false"
          empty-text="No assignable products available"
        >
          <el-table-column min-width="230">
            <template #default="props">
              <div class="prodnamediv">
                <img src="@/assets/icons/vcicon.svg" class="cellimage" />
                <div style="text-align: start">
                  <h6
                    class="font-bold"
                    style="font-size: 14px; line-height: 19.07px"
                  >
                    {{ props.row.productName }}
                  </h6>
                  <label>
                    Version: {{ props.row.majorVersionNumber }}.{{
                      props.row.minorVersionNumber
                    }}
                  </label>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #default="props">
              {{ props.row.seatsUsed }}&#47;{{ props.row.seats }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <label class="headerfont topmargin" v-if="productSelected">
        Select available add-on
      </label>
      <div class="overflowdiv topmargin" v-if="productSelected">
        <el-table
          id="prodtoassigntable"
          v-loading="addonLoading"
          :data="addons"
          :border="true"
          style="width: 100%"
          :row-style="rowdatastyle"
          highlight-current-row
          @selection-change="SelectedAddOns"
          :row-class-name="tableRowClassName"
          :show-header="false"
          empty-text="No assignable products available"
        >
          <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column min-width="230">
            <template #default="props">
              <div class="prodnamediv">
                <img src="@/assets/icons/addonicon.svg" class="cellimage" />
                <div style="text-align: start">
                  <h6
                    class="font-bold"
                    style="font-size: 14px; line-height: 19.07px"
                  >
                    {{ props.row.productName }}
                  </h6>
                  <label>
                    Version: {{ props.row.majorVersionNumber }}.{{
                      props.row.minorVersionNumber
                    }}
                  </label>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #default="props">
              {{ props.row.seatsUsed }}&#47;{{ props.row.seats }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="buttonsdiv">
      <el-button
        id="accassignprodnext"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="nextClickHandler"
        v-if="taskType === 'productAccount'"
        :disabled="!showNext"
      >
        Next
      </el-button>
      <el-button
        id="assignprodnext"
        round
        class="btnstyle ml-4"
        variant="warning"
        @click="nextHandler"
        v-if="taskType === 'prodAss'"
        :disabled="!showNext"
      >
        Next
      </el-button>
      <el-button
        id="prodassigncancel"
        round
        variant="outline-dark"
        @click="cancelInvite"
        v-if="taskType === 'productAccount' || taskType === 'prodAss'"
        class="cancelbtn"
        >Cancel</el-button
      >
    </div>
  </div>

  <AssignUsersModal v-if="taskType === 'account'" />
  <div v-if="taskType === 'summary'" class="modalbody">
    <SummaryViewModal
      :assignedProduct="selectedProduct"
      dataof="product"
      :selectedAddon="selectedAddons"
    />
    <div v-if="showError" style="display: flex" class="maxitems">
      <img
        src="@/assets/icons/warnerror.svg"
        class="cellimage"
        style="margin-top: 10px"
      />
      <label
        class="errordiv"
        v-if="addUserError !== ''"
        style="padding: 1rem 0"
      >
        {{ addUserError }}</label
      >
    </div>
    <div class="buttonsdiv">
      <el-button
        round
        class="btnstyle ml-4"
        variant="warning"
        id="prodassingconfirm"
        @click="sendInvite"
        :disabled="!enableControls"
      >
        <i v-if="!enableControls" class="fa fa-refresh fa-spin"></i> Confirm
      </el-button>
      <el-button
        id="prodassigncancel"
        round
        variant="outline-dark"
        @click="cancelInvite"
        class="cancelbtn"
        >Cancel</el-button
      >
    </div>
  </div>
  <div v-if="taskType === 'emailSent'" class="modalbody">
    <div class="innerModal">
      <img
        src="@/assets/icons/mailsent.svg"
        style="width: 70px; margin-bottom: 1rem"
      />
      <label class="labelbld">Notification email sent to: </label>
      <div class="usersdiv">
        <div
          v-for="(item, index) in selectedUsers"
          :key="index"
          style="display: flex"
          class="btm-1"
        >
          <p>
            {{ item.name }}<br />
            &#40;{{ item.email }} &#41;
          </p>
        </div>
      </div>
    </div>
    <div class="buttonsdiv">
      <el-button
        id="prodassigndone"
        round
        class="btnstyle"
        variant="warning"
        @click="cancelInvite"
        style="float: right"
      >
        Done</el-button
      >
    </div>
  </div>
</template>

<style lang="scss">
.overflowdiv {
  max-height: 55% !important;
  overflow: auto;
  border: 1px solid #bdbdbd;
  padding: 8px 12px;
}
.labelbld {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333333;
}
.labeldiv {
  display: flex;
  flex-direction: column;
}
.headerfont {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.datadiv {
  display: flex;
  flex-direction: column;
  height: 95%;
}
.canbtn {
  font-size: 16px;
  line-height: 24px;
}
.prodnamediv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.btndiv {
  display: flex;
  flex-flow: row-reverse;
}
.el-table .disable-row {
  background: #bdbdbd;
  pointer-events: none;
  border: 1px solid #bdbdbd;
  opacity: 0.2;
}
.el-table--border .el-table__cell {
  border-right: 0px !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import APIService from "@/utils/APIService";
import { ProductInfomation } from "@/utils/AppInterfaces";
import _ from "lodash";
import { FlowActionTypes } from "@/store/flowstore/FlowActionTypes";
import { UserActionTypes } from "@/store/userstore/UserActionTypes";
import { CompanyActionTypes } from "@/store/companystore/CompanyActionTypes";
import { ProductActionTypes } from "@/store/productstore/ProductActionTypes";
import AssignUsersModal from "./AssignUsersModal.vue";
import SummaryViewModal from "./SummaryViewModal.vue";

export default defineComponent({
  name: "AssignProductsModal",
  components: {
    AssignUsersModal,
    SummaryViewModal,
  },
  computed: {
    ...mapGetters([
      "getAvailableProducts",
      "getUserData",
      "getSelectedUsers",
      "getTaskType",
      "getCompanyData",
      "getCurrentUser",
      "getAddonsList",
    ]),
    products(): any {
      if (!this.loading && !this.productsLoaded)
        this.getAvailableProductsList();

      return this.getAvailableProducts.items;
    },
    addons(): any {
      if (
        this.productSelected &&
        this.getAddonsList.length === 0 &&
        this.addonLoading
      )
        this.getSubscriptionAvailableAddOns();
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      else this.addonLoading = false;
      return this.getAddonsList;
      // if (
      //   this.productSelected &&
      //   this.getAddonsList.length === 0 &&
      //   !this.addonLoading
      // )
      //   this.getSubscriptionAvailableAddOns();
      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // else this.addonLoading = false;
      // return this.getAddonsList;
    },
    selectedUsers(): any {
      return this.getSelectedUsers.length !== undefined
        ? this.getSelectedUsers
        : [this.getUserData];
    },
    taskType(): any {
      return this.getTaskType;
    },
    showNext(): any {
      return !_.isEmpty(this.selectedProduct);
    },
  },
  data() {
    return {
      selectedProduct: {} as unknown as ProductInfomation,
      userdetails: this.userData,
      enableControls: true,
      currentRow: null as unknown as ProductInfomation,
      showError: false,
      addUserError: "",
      rowdatastyle: {
        "font-family": "Open Sans, sans-serif",
        "font-weight": "400",
        "font-size": "0.8rem",
        "line-height": "1rem",
        color: "black",
      },
      productsLoaded: false,
      loading: true,
      productSelected: false,
      addonLoading: true,
      selectedAddons: [],
    };
  },
  methods: {
    tableRowClassName({ row }: { row: ProductInfomation }) {
      if (row.seats === row.seatsUsed) {
        return "disable-row";
      }
      return "";
    },
    getAvailableProductsList() {
      this.productsLoaded = false;
      this.loading = true;
      let companyId = "";
      switch (this.$route.name) {
        case "account":
          companyId = this.getCurrentUser.result.companyBucketId;
          break;
        case "companies":
          companyId = this.getCompanyData.companyBucketId;
          break;
        case "user":
          companyId = this.getUserData.companyBucketId;
          break;
        default:
      }
      const apiService = new APIService(this.axios);
      apiService.getAvailableProductSubscriptions(companyId).then((res) => {
        this.$store.dispatch(ProductActionTypes.SET_AVAILABLE_PRODUCTS, res);
        this.productsLoaded = true;
        this.loading = false;
      });
    },
    getSubscriptionAvailableAddOns() {
      const mainProductInfomation = {
        mainProductBucketId: this.selectedProduct.productBucketId,
        mainProductMajorVersion: this.selectedProduct.majorVersionNumber,
        mainProductMinorVersion: this.selectedProduct.minorVersionNumber,
        companyBucketIdList: [this.selectedProduct.companyBucketId],
      };
      const apiService = new APIService(this.axios);
      apiService
        .getSubscriptionAddonsList(mainProductInfomation)
        .then((res) => {
          this.$store.dispatch(ProductActionTypes.SET_ADDONS_LIST, res.items);
          this.productsLoaded = true;
          this.addonLoading = false;
        });
    },
    nextHandler() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "summary");
    },
    nextClickHandler() {
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "account");
    },
    handleCurrentChange(val: ProductInfomation) {
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, val);
      this.selectedProduct = val;
      this.productSelected = true;
      this.getSubscriptionAvailableAddOns();
      if (val.seats !== val.seatsUsed) {
        this.selectedProduct = val;
        this.currentRow = val;
      }
      return "";
    },
    SelectedAddOns(selected: any) {
      this.selectedAddons = selected;
    },
    sendInvite() {
      this.enableControls = false;
      const addOnsList: any[] = [];
      addOnsList.push({
        productBucketId: this.selectedProduct.productBucketId,
        major: this.selectedProduct.majorVersionNumber,
        minor: this.selectedProduct.minorVersionNumber,
        patch: this.selectedProduct.patchVersionNumber,
      });
      this.selectedAddons.map((item: any) => {
        addOnsList.push({
          productBucketId: item.productBucketId,
          major: item.majorVersionNumber,
          minor: item.minorVersionNumber,
          patch: item.patchVersionNumber,
        });
      });
      const apiService = new APIService(this.axios);
      const multipleRequestData = [] as any[];

      this.selectedUsers.map((user: any) => {
        const inviteObj = {
          companyBucketId: user.companyBucketId,
          userBucketId: user.userBucketId,
          entitlementsToAssign: addOnsList,
        };

        multipleRequestData.push(inviteObj);
      });
      apiService.assignMultipleSubscription(multipleRequestData).then((res) => {
        if (res.areAllSuccessfull) {
          // apiService
          //   .getAssignedProdsList(this.getUserData.userBucketId, "Ascending")
          //   .then((response) => {
          //     this.$store.dispatch(
          //       UserActionTypes.SET_ASSIGNED_PRODUCTS,
          //       response
          //     );
          //   });
          apiService.getProductList().then((resp) => {
            this.$store.dispatch(ProductActionTypes.SET_PRODUCTS, resp);
          });
          apiService.getSubscriptionsList().then((res) => {
            this.$store.dispatch(ProductActionTypes.SET_SUBSCRIPTIONS, res);
          });
          const subInfo = {
            productBucketId: this.selectedProduct.productBucketId,
            majorVersionNumber: this.selectedProduct.majorVersionNumber,
            minorVersionNumber: this.selectedProduct.minorVersionNumber,
            patchVersionNumber: this.selectedProduct.patchVersionNumber,
          };
          apiService.getProduct(subInfo).then((prod) => {
            this.$store.dispatch(
              FlowActionTypes.SET_SUBSCRIPTION_DATA,
              prod.items[0]
            );
          });
          if (this.$route.name === "companies") {
            apiService
              .getSubscriptionsListWithCompanyBucketId(
                this.getCompanyData.companyBucketId
              )
              .then((response) => {
                this.$store.dispatch(
                  CompanyActionTypes.SET_COMPANY_SUBSCRIPTIONS,
                  response
                );
              });
          }
          this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "emailSent");
        } else {
          this.showError = true;
          let errormessage = "";
          errormessage = res.validationResult.validationMessages.join(" ");
          this.showError = true;
          this.addUserError = errormessage;
        }
      });
      return !this.enableControls;
    },
    cancelInvite() {
      this.selectedProduct = {} as ProductInfomation;
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDPRODUCTS, false);
      this.$store.dispatch(FlowActionTypes.SET_SHOW_ADDUSER, false);
      this.$store.dispatch(FlowActionTypes.SET_SELECTED_USERS, []);
      this.$store.dispatch(FlowActionTypes.SET_PRODUCT_DATA, {});
      this.$store.dispatch(FlowActionTypes.SET_TASK_TYPE, "none");
      this.$store.dispatch(ProductActionTypes.SET_AVAILABLE_PRODUCTS, {
        items: [],
      });
      this.$store.dispatch(
        UserActionTypes.SET_UNASSIGNED_SUBSCRIPTIONUSERS,
        {}
      );
    },
  },
  mounted() {
    this.getAvailableProductsList();
  },
  props: {
    userData: null,
  },
});
</script>
