<template>
  <div class="innermodaloverflow">
    <label class="font-bold-16">Summary </label>
    <div v-for="(addon, index) in addonsList" :key="index" class="addonlistdiv">
      <label class="font-regular-16 btm-1">
        Add-on "<strong>{{ addon.productName }}</strong
        >" will be assigned to users
      </label>
      <div class="userlistdiv">
        <div v-for="(item, index) in selectedUsers" :key="index">
          <!-- <p style="margin: 0"> -->
          <label class="font-regular-16">
            {{ item.firstName }}&#44; {{ item.lastName }}&#44; {{ item.email }}
          </label>
          <!-- </p> -->
        </div>
      </div>

      <!-- {{ addon }} -->
    </div>
  </div>
</template>

<style lang="scss">
.userlistdiv {
  border: solid 1px #bfbfbf;
  min-height: 7rem;
  padding: 1rem;
  color: #292929;
  overflow: auto;
  margin-top: 0.75rem;
}
.innermodaloverflow {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90%;
  overflow: auto;
}

.addonlistdiv {
  color: #292929;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import { ProductInfomation, AddonInformation } from "@/utils/AppInterfaces";

export default defineComponent({
  name: "AddonSummaryModal",
  computed: {
    ...mapGetters(["getSelectedUsers"]),
    selectedUsers(): any {
      return this.getSelectedUsers.length !== undefined
        ? this.getSelectedUsers
        : [];
    },
    addonsList(): any {
      return this.selectedAddonList;
    },
  },
  data() {
    return {
      product: this.assignedProduct || ({} as ProductInfomation),
      detailsOf: this.dataof,
      //   selectedAddons: this.selectedAddonList,
    };
  },
  props: {
    assignedProduct: {
      type: Object as PropType<ProductInfomation>,
      required: false,
    },
    dataof: {
      type: String,
      required: false,
    },
    selectedAddonList: {
      type: Array as PropType<AddonInformation[]>,
      required: false,
    },
  },
});
</script>
