<template>
  <div class="errcontainer">
    <div class="errorinnerdiv">
      <div class="errortextdiv">
        <img src="@/assets/icons/erroricon.svg" class="imagestyle" />
        <h4><b> Connection time out. </b></h4>
      </div>
      <div class="timeoutdiv">
        <el-text class="normalerrortext"
          >Request timed out, login or refresh the page to continue the
          session.</el-text
        >
        <div style="align-self: center">
          <el-button
            id="gotologin"
            round
            class="btnstyle"
            variant="warning"
            @click="gotoLogin"
          >
            Go to Login</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.errcontainer {
  box-sizing: border-box;
  flex-direction: column;
  background: #ffffff;
  margin: 10vh 20vw;
  padding: 1% 4vw;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeoutdiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { msalInstance } from "@/authConfig";

export default defineComponent({
  name: "ErrorMessage",
  methods: {
    async gotoLogin() {
      await msalInstance.logoutRedirect();
    },
  },
});
</script>
