/* eslint-disable */
//import SSOAuth from "./SSOAuth";
import { useTimeout } from "element-plus";
import { msalInstance, tokenRequest } from "../authConfig";

export default class APIService {
  axios: any;
  headers: { Authorization: string; "Content-Type": string };
  constructor(axiosService: any) {
    this.axios = axiosService;
    this.headers = {
      Authorization: "TOKEN_MISSING",
      "Content-Type": "application/json",
    };
  }

  async getAccessToken() {
    try {
      const response = await msalInstance.acquireTokenSilent(tokenRequest);
      this.headers.Authorization = `Bearer ${response.accessToken}`;
    } catch (error) {
      console.error(`Failed to acquire token! ${error}`);
    }
  }

  // *******     Product Routes     *********

  async getAssignedUsersList(
    productBucketId: any,
    searchtext: any ,
    skipval: number,
    takeval: number
  ) {
    let url = "";
    if (searchtext !== null) {
      url = `api/product/searchassigned?productBucketId=${productBucketId}&searchphrase=${searchtext}&skip=${skipval}&take=${takeval}`;
    } else {
      url = `api/product/searchassigned?productBucketId=${productBucketId}&skip=${skipval}&take=${takeval}`;
    }
    await this.getAccessToken();
    const resp = await this.axios.get(url, {
      headers: this.headers,
      timeout: 30000
    });
    return resp.data;
  }

  async getAssignedUsersSubList(subscriptionData: any) {
    await this.getAccessToken();
    const resp = await this.axios.post(
      "api/product/searchassignedSubscription",
      subscriptionData,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return resp.data;
  }

  async getUnAssignedUsersSubList(subscriptionData: any) {
    await this.getAccessToken();
    const resp = await this.axios.post(
      "api/product/searchUnassignedSubscriptionUsers",
      subscriptionData,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return resp.data;
  }

  async getProductList(productObject: any) {
    await this.getAccessToken();
    const res = await this.axios.post("/api/Product/ProductListNoSubscriptionSeats",productObject, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async getSubscriptionAddonsList(mainProductData: any) {
    await this.getAccessToken();
    const res = await this.axios.post("/api/Account/GetAvailableAddOnsSubscription", mainProductData, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
    
  }

  async getProduct(productInfo: any) {
    await this.getAccessToken();
    const res = await this.axios.post(
      "/api/Product/getProductInfo",
      productInfo,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  async getSubscriptionsList(productObject: any) {
    await this.getAccessToken();
    const res = await this.axios.post("/api/Product/ProductListSubscriptionSeats", productObject, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async getSubscriptionsListWithCompanyBucketId(companyInfo: any) {
    await this.getAccessToken();
    const res = await this.axios.post(
      "/api/Product/ProductListSubscriptionSeatsWithCompanyBucketId",
      companyInfo,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }
  // Removal of multiple entitlments products - Addons from single user 
  async removeEntitlements(removeRequest: any) {
    await this.getAccessToken();
    const res = await this.axios.post(
      "/api/Product/RemoveEntitlements",
      removeRequest,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }
  // Removal of multiple entitlements products - Addons from multiple users 
  async removeMultipleEntitlements(multipleRequests: any) {
    const requestdata = {
      requests: multipleRequests
    }
    await this.getAccessToken();
    const res = await this.axios.post(
      "/api/Product/RemoveEntitlementsFromUsers",
      requestdata,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  // Removal of multiple Subscription products - Addons from single user 
  async removeSubscriptions(removeRequest: any) {
    await this.getAccessToken();
    const res = await this.axios.post(
      "/api/Product/RemoveSubscriptions",
      removeRequest,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }
 // Removal of multiple Subscription products - Addons from multiple users 
  async removeMultipleSubscriptions(multipleRequests: any) {
    const requestdata = {
      requests: multipleRequests
    }
    await this.getAccessToken();
    const res = await this.axios.post(
      "/api/Product/RemoveSubscriptionsFromUsers",
      requestdata,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  async getProductEntitlementsList(
    productBucketId: string,
    companyBucketId: string
  ) {
    await this.getAccessToken();
    const res = await this.axios.get(
      `/api/Product/ProductEntitlementsListByBucketId ?companyBucketId=${companyBucketId}&productBucketId=${productBucketId}`,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  async getSubscritionEntitlementsList(
    productBucketId: string,
    companyBucketId: string
  ) {
    await this.getAccessToken();
    const res = await this.axios.get(
      `/api/Product/ProductEntitlementsListSubscriptionByBucketId(?companyBucketId=${companyBucketId}&productBucketId=${productBucketId}`,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  // *******     User Routes     *********

  async getAssignedProdsList(userData: any) {
    await this.getAccessToken();
    const resp = await this.axios.post(
      "api/User/GetUserProductsByBucketId", userData,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return resp.data;
  }

  async getCompanies() {
    await this.getAccessToken();
    const res = await this.axios.get("/api/user/getusercompanies", {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async getCurrentUser() {
    await this.getAccessToken();
    const resp = await this.axios.get("/api/User/GetCurrentAccountsUser", {
      headers: this.headers,
      timeout: 30000
    });
    return resp.data;
  }

  async deleteUsers(emailList: any) {
    await this.getAccessToken();
    const res = await this.axios.post("/api/User/DeleteUsers", emailList, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async createUser(userdata: any) {
    await this.getAccessToken();
    const res = await this.axios.post("api/User/CreateUser", userdata, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async getFreeProducts() {
    await this.getAccessToken();
    const res = await this.axios.get("api/user/FreeProductsList", {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async getUsersSimple() {
    await this.getAccessToken();
    const res = await this.axios.get("api/user/getuserssimple", {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async getUsersWithoutAdmins(requestObject: any) {
    await this.getAccessToken();
    const res = await this.axios.post(
      "api/user/FindUsersWithoutCompanyAdmins",
      requestObject,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  async promoteUsersToCompanyAdmin(roleData: any) {
    await this.getAccessToken();
    const res = await this.axios.post("api/user/PromoteUsersToCompanyAdmin", roleData, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async addNewCompanyAdmin(userData: any) {
    await this.getAccessToken();
    const res = await this.axios.post("api/user/createcompanyadmin", userData, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  async removeCompanyAdmin(userData: any) {
    await this.getAccessToken();
    const res = await this.axios.post(
      "api/user/DemoteUsersFromCompanyAdmin",
      userData,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  // *******     Account Routes     *********

  async getUsersList(userdata: any) {
    await this.getAccessToken();
    const res = await this.axios.post("api/account/search", userdata, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }
  // Assignment to multiple users with multiple without Subscription products - Addons
  async assignMultipleEntitlement(multiplerequests: any) {
    const requestdata = {
      requests: multiplerequests
    }
    await this.getAccessToken();
    const res = await this.axios.post("/api/Account/AddEntitlementsToUsers", requestdata, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }
  // Assignment to Single user with multiple without Subscription products - Addons
  async assignEntitlement(userdata: any) {
    await this.getAccessToken();
    const res = await this.axios.post("api/Account/Addentitlement", userdata, {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  // Assignment to multiple users with multiple Subscription products - Addons
  async assignMultipleSubscription(multiplerequests: any) {
    const requestdata = {
      requests: multiplerequests
    }
    await this.getAccessToken();
    const res = await this.axios.post(
      "/api/Account/AddEntitlementsToUsersSubscription",
      requestdata,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }
  // Assignment to single users with multiple Subscription products - Addons
  async assignSubscription(userdata: any) {
    await this.getAccessToken();
    const res = await this.axios.post(
      "api/Account/AddEntitlementsSubscription",
      userdata,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }


  async getAvailableProductSubscriptions(companyBucketId?: string) {
    let url = '';
    if (companyBucketId == null) {
      url = "/api/Account/GetAvailableProductsSubscriptionWithBucketId";
    } else {
      url = `/api/Account/GetAvailableProductsSubscriptionWithBucketId?companyBucketId=${companyBucketId}`;
    }
    await this.getAccessToken();
    const res = await this.axios.get(
      url,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  async getAccountCompanies() {
    await this.getAccessToken();
    const res = await this.axios.get("/api/Account/GetCompanies", {
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }

  // *******     Company Routes     *********

  async getCompanySearch(companyDataobject: any) {
    await this.getAccessToken();
    const res = await this.axios.post(
      "/api/Company/search",companyDataobject,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  async getCompLicAdmins(companyBucketId: string) {
    await this.getAccessToken();
    const res = await this.axios.get(
      `/api/Company/GetCompanyLicenseAdminsWithBucketId?companyBucketId=${companyBucketId}`,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  async getSubLicUsage(companyBucketId: string) {
    await this.getAccessToken();
    const res = await this.axios.get(
      `/api/Company/GetCompanyLicenseUsageWithBucketId?companyBucketId=${companyBucketId}`,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  async getCompanyUserCount(companyBucketId: string) {
    await this.getAccessToken();
    const res = await this.axios.get(
      `/api/company/GetCompanyUsersWithBucketId?companyBucketId=${companyBucketId}`,
      {
        headers: this.headers,
        timeout: 30000
      }
    );
    return res.data;
  }

  // *******     Document Routes     *********

  async getReleaseNotes() {
    await this.getAccessToken();
    const res = await this.axios.get("/api/Document/GetLatestReleaseNotes", {
      responseType: "blob",
      headers: this.headers,
      timeout: 30000
    });
    return res.data;
  }
}
