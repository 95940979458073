/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="viewopts">
    <div class="prodiv font-regular-18">
      <img
        src="@/assets/icons/vcicon.svg"
        style="width: 48px; margin-right: 20px"
      />
      <div style="text-align: start">
        <label style="margin: unset">{{
          dataof.productName.substring(0, 18)
        }}</label>
        <label class="font-bold-18">{{
          dataof.productName.substring(18)
        }}</label>
      </div>
      <!-- <div style="text-align: start">
        <h5 class="font-bold-18">{{ dataof.productName }}</h5>
      </div> -->
    </div>
    <div class="subdiv">
      <h5 class="font-bold-18" style="margin: unset">
        {{ dataof.majorVersionNumber }}.{{ dataof.minorVersionNumber }}
      </h5>
      <label class="font-regular-14 marginright">Version:</label>
    </div>
    <div class="subdiv">
      <h5 class="font-bold-18" style="margin: unset">
        {{ dataof.seatsUsed }}&#47;{{ dataof.seats }}
      </h5>
      <label class="font-regular-14 marginright">Product Seats:</label>
    </div>
    <div class="userprodiv">
      <el-link
        id="downloadlink"
        class="downloadbtn"
        :underline="false"
        :href="dataof.installer"
        target="_blank"
      >
        Download
        <img
          src="@/assets/icons/download.svg"
          style="width: 24px; margin-left: 5px"
        />
      </el-link>
    </div>
  </div>
  <div v-for="(addon, index) in addoninfo" :key="index" class="addonviewopts">
    <div class="addonnamediv">
      <img
        src="@/assets/icons/addonicon.svg"
        style="width: 25px; margin-right: 20px"
      />
      <div style="text-align: start">
        <label class="font-bold-14" style="margin: unset">
          {{ addon.productName }}
        </label>
      </div>
      <!-- <div style="text-align: start">
        <h5 class="font-bold-18">{{ dataof.productName }}</h5>
      </div> -->
    </div>
    <div class="subdiv"></div>
    <div class="subdiv marginright">
      <h5 class="font-bold-16" style="margin: unset">
        {{ addon.seatsUsed }}&#47;{{ addon.seats }}
      </h5>
      <label class="font-regular-14 marginright">Add-on Seats:</label>
    </div>
  </div>
  <!-- <div class="addonviewopts bottommargin">
    <div class="addonnamediv">
      <img
        src="@/assets/icons/addonicon.svg"
        style="width: 25px; margin-right: 20px"
      />
      <div style="text-align: start">
        <label class="font-bold-14" style="margin: unset">
          {{ addoninfo.productName }}
        </label>
      </div>
      <div style="text-align: start">
        <h5 class="font-bold-18">{{ dataof.productName }}</h5>
      </div> 
    </div>
    <div class="subdiv"></div>
    <div class="subdiv marginright">
      <h5 class="font-bold-16" style="margin: unset">
        {{ addoninfo.seatsUsed }}&#47;{{ addoninfo.seats }}
      </h5>
      <label class="font-regular-14 marginright">Add-on Seats:</label>
    </div>
  </div> -->
</template>

<style lang="scss">
.name-bold {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 24px;
}

.viewutils {
  position: relative;
  display: flex;
  flex-direction: column;
}
.detrowstyle {
  display: flex;
  flex-direction: row;
  text-align: start;
  padding: 0 3% 1% 5%;
}
.downloadbtn {
  color: #ffffff;
  background: #ffbd2c;
  border: #ffbd2c;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  width: 10rem;
  height: 2.5rem;
  border-radius: 20px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import _ from "lodash";

export default defineComponent({
  name: "SubscriptionSubDetails",
  computed: {
    ...mapGetters([
      "getProductData",
      // "getUserData",
      "getSubscriptionData",
      "getAddonData",
    ]),
    dataof(): any {
      if (!_.isEmpty(this.getSubscriptionData) && this.$route.name !== "user") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.overviewName = "Subscriptions";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.detailName = "Subscription";
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.detailname =
        !_.isEmpty(this.getSubscriptionData) && this.$route.name !== "user"
          ? "subscription"
          : "product";

      return _.isEmpty(this.getSubscriptionData)
        ? this.getProductData
        : this.getSubscriptionData;
    },
    addoninfo(): any {
      return !_.isEmpty(this.getAddonData) ? this.getAddonData : "";
    },
  },
  data() {
    return {
      detailname: this.detailOf,
      showDeleteDailogue: false,
      overviewName: "",
      detailName: "",
    };
  },
  props: {
    detailOf: String,
  },
});
</script>
