<template>
  <div class="innerModal">
    <div class="bottommargin labeldiv" v-if="detailsOf === 'product'">
      <label class="summarytitle btm-1">Assigned product:</label>
      <label class="titlestyle btm-1">{{ product.productName }}</label>
      <!-- <div class="summarytitle topmargin btm-1">Version:</div> -->
      <label class="bottommargin">
        Version: {{ product.majorVersionNumber }}.{{
          product.minorVersionNumber
        }}
      </label>
      <label class="summarytitle btm-1">Assigned add-on:</label>
      <div v-for="(item, index) in selectedAddons" :key="index">
        <div class="titlestyle btm-1">{{ item.productName }}</div>
        <!-- <div class="summarytitle topmargin btm-1">Version:</div> -->
        <div class="btm-2">
          Version: {{ item.majorVersionNumber }}.{{ item.minorVersionNumber }}
        </div>
      </div>
      <label class="summarytitle topmargin btm-1">License type:</label>
      <label class="titlestyle bottommargin">{{ product.licenseType }}</label>
      <label class="summarytitle btm-1">Assigned users:</label>
      <div class="sumusersdiv">
        <div v-for="(item, index) in selectedUsers" :key="index">
          <p style="margin: 0">
            <strong>{{ item.name }}</strong
            ><br />
            &#40;{{ item.email }} &#41;
          </p>
        </div>
      </div>
    </div>
    <div class="bottommargin labeldiv" v-if="detailsOf === 'addon'">
      <div v-for="(item, index) in selectedAddons" :key="index">
        <label class="summarytitle btm-1">Assigned add-on:</label>
        <div class="titlestyle btm-1">{{ item.productName }}</div>
        <!-- <div class="titlestyle topmargin btm-1">Version:</div> -->
        <div class="btm-2">
          Version: {{ item.majorVersionNumber }}.{{ item.minorVersionNumber }}
        </div>
        <div class="summarytitle">License type:</div>
        <div class="titlestyle bottommargin">{{ item.licenseType }}</div>
      </div>

      <label class="summarytitle topmargin btm-1">Assigned users:</label>
      <div class="sumusersdiv">
        <div v-for="(item, index) in selectedUsers" :key="index">
          <label class="font-regular-16">
            {{ item.firstName }}&#44; {{ item.lastName }}
            {{ item.lastname }}&#44; {{ item.email }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.summarytitle {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20.55px;
  color: #bfbfbf;
}

.labeldiv {
  display: flex;
  flex-direction: column;
}
.sumusersdiv {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 50%;
}
</style>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import { ProductInfomation, AddonInformation } from "@/utils/AppInterfaces";

export default defineComponent({
  name: "SummaryViewModal",
  computed: {
    ...mapGetters(["getSelectedUsers", "getUserData"]),
    selectedUsers(): any {
      return this.getSelectedUsers !== undefined &&
        this.getSelectedUsers.length !== 0
        ? this.getSelectedUsers
        : [this.getUserData];
    },
  },
  data() {
    return {
      product: this.assignedProduct || ({} as ProductInfomation),
      detailsOf: this.dataof,
      selectedAddons: this.selectedAddon || ([] as AddonInformation[]),
    };
  },
  props: {
    assignedProduct: {
      type: Object as PropType<ProductInfomation>,
      required: false,
    },
    dataof: {
      type: String,
      required: false,
    },
    selectedAddon: {
      type: Array as PropType<AddonInformation[]>,
      required: false,
    },
  },
});
</script>
