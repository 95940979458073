export const state = {
  //FlowState
  errors: null,
  showDetails: false,
  showAdduser: false,
  showSideHeader: false,
  selectedEmails: [],
  usersSelected: [],
  selectedProducts: [],
  productData: {},
  userData: {},
  showAddProducts: false,
  subscriptionData: {},
  addonData: {},
  selectedUserids: [],
  addonUsers: [] as any[],
  addonProducts: [] as any[],
  showAssignAddon: false,
  showRemoveAddon: false,
  showRemoveEntitlement: false,
  taskType: "",
  currentPage: 1,
  searchValue: "",
  //Auth state
  user: {},
  showUserprofile: false,
  errorOccured: false,
  //Company state
  companies: [],
  allCompanies: [],
  companyData: {},
  licAdmins: [],
  showAddCompanyAdmin: false,
  companySubscriptions: [],
  licUsage: {},
  compUserCount: {},
  //Product State
  productsList: [],
  AssignedUserList: [],
  productEntitlements: [],
  availableProducts: [],
  subscriptionsList: [],
  showSubscriptionDetails: false,
  addonsList: [],
  //User Store
  usersList: [],
  assProdList: [],
  currentUser: {},
  userAddons: [],
  usersWithoutAdmins: [],
  companyUsers: [],
  unassignedSubscriptionUsers: [],
  userError: false,
};

export type State = typeof state;
